import axios from "./base";
import {
  Collections,
  GetCollectionsParams,
  UpdateCollection,
} from "./types/collection";

export const getCollections = async (
  params: GetCollectionsParams
): Promise<{ collections: Collections[]; total: number }> => {
  const response = await axios.post("/collections/query", params);
  return response.data;
};

export const addCollection = async (collection: UpdateCollection) => {
  await axios.post("/collections", collection);
};
export const getCollectionById = async (id: string): Promise<Collections> => {
  const response = await axios.get(`/collections/${id}`);
  return response.data.collection;
};
export const updateCollection = async (
  id: string,
  collection: UpdateCollection
) => {
  await axios.put(`/collections/${id}`, collection);
};
