import { useState, useEffect } from "react";
import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import { Store, GetStoresParams } from "../../lib/api/types/store";
import { getStores, getCities } from "../../lib/api/stores";
import Paginator from "../../shared/paginator";
import { format } from "date-fns";

interface StoreTableProps {
  onEditStore: (store: Store) => void;
  openedSideBar: boolean;
}

const StoreTable: React.FC<StoreTableProps> = ({
  onEditStore,
  openedSideBar,
}) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [selectedCity, setSelectedCity] = useState<string[] | null>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [data, setData] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchStores = async (params: GetStoresParams) => {
    setLoading(true);
    const response = await getStores(params);
    setData(response.stores.sort((a, b) => (a.active && !b.active ? -1 : 1)));
    setTotal(response.total);
    setLoading(false);
  };

  const fetchCities = async (params: GetStoresParams) => {
    try {
      const response = await getCities(params);
      setCities(response.cities);
    } catch (error) {
      console.error("Failed to fetch cities:", error);
    }
  };

  useEffect(() => {
    const allCities =
      selectedCity?.includes("Все") ? cities : selectedCity;
    const offset = (activePage - 1) * selectedRowsNumber;

    let added_from = null;
    let added_to = null;

    if (dateValue[0]) {
      const startDate = new Date(dateValue[0]);
      startDate.setHours(0, 0, 0, 0);
      added_from = startDate.toISOString();

      if (dateValue[1]) {
        const endDate = new Date(dateValue[1]);
        endDate.setHours(23, 59, 59, 999);
        added_to = endDate.toISOString();
      } else {
        const endDate = new Date(dateValue[0]);
        endDate.setHours(23, 59, 59, 999);
        added_to = endDate.toISOString();
      }
    }

    const params: GetStoresParams = {
      search: searchId,
      added_from: added_from,
      added_to: added_to,
      cities: allCities ? allCities : null,
      active:
        selectedStatus?.includes("Все") || selectedStatus === null
          ? [false, true]
          : selectedStatus?.map((status) => status === "Активен"),
      offset: offset,
      limit: selectedRowsNumber,
    };
    fetchStores(params);
    fetchCities(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchId,
    dateValue,
    selectedStatus,
    selectedCity,
    activePage,
    selectedRows,
  ]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    setSelectedCity(null);
    setPage(1);
  };

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Активен", "Не активен"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const handleCitiesChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedCity?.includes("Все")) {
        setSelectedCity(["Все", ...cities]);
      } else if (selectedCity?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedCity(newStatus);
      }
    } else {
      if (selectedCity?.includes("Все")) {
        setSelectedCity(null);
      } else if (value.length === 0) {
        setSelectedCity(null);
      } else {
        setSelectedCity(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 2, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: openedSideBar ? `calc(100% - 5px)` : '',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Город"
        data={["Все", ...cities]}
        value={selectedCity ? selectedCity : []}
        onChange={handleCitiesChange}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID магазина",
    "Название",
    "Город",
    "Адрес",
    "Дата создания",
    "Статус активности",
  ];

  const headerDataKeyMap = {
    "ID магазина": "id",
    "Название": "name",
    "Город": "city",
    "Адрес": "address",
    "Дата создания": "creationDate",
    "Статус активности": "status",
  };

  const clickableHeaders = ["Название"];
  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активен":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Не активен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (store: Store) => {
    onEditStore(store);
  };

  const formattedData = data.map((store) => ({
    id: store.store_id.toString(),
    name: store.internal_name,
    city: store.city,
    address: store.address,
    creationDate: format(new Date(store.added), "dd.MM.yyyy"),
    status: store.active ? "Активен" : "Не активен",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="stores"
          customData={data}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default StoreTable;
