import { Button, Group } from "@mantine/core";
import { useState } from "react";
import ArticleForm from "../components/articles/articleForm";
import ArticlesTable from "../components/articles/articlesTable";
import { Article } from "../lib/api/types/articles";

const Articles = ({ openedSideBar }: { openedSideBar: boolean }) => {
  const [editArticle, setEditArticle] = useState(false);
  const [articleId, serArticleId] = useState<string | null>(null);
  const [title, setTitle] = useState("Создание статьи");

  const edit = (value: boolean, article: Article | null) => {
    setEditArticle(value);
    if (article) {
      serArticleId(article?.id);
      setTitle("Редактирование статьи");
    }
  };
  const handleCreateArticleClick = () => {
    setEditArticle(true);
    setTitle("Создание статьи");
    serArticleId(null);
  };

  return (
    <>
      {editArticle ? (
        <ArticleForm title={title} editArticle={edit} id={articleId} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Статьи</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={handleCreateArticleClick}
              >
                + Создать статью
              </Button>
            </Group>
          </div>
          <ArticlesTable
            onRowClick={(article: Article) => edit(true, article)}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
};

export default Articles;
