import React from "react";
import {
  Checkbox,
  Combobox,
  Group,
  Input,
  Pill,
  PillsInput,
  useCombobox,
} from "@mantine/core";

import { Selector } from "tabler-icons-react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

interface MultiSelectCheckboxProps {
  data: string[];
  placeholder: string;
  value: string[];
  onChange: (value: string[]) => void;
  style?: React.CSSProperties;
  label?: string;
}

export const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = ({
  data,
  placeholder,
  value,
  onChange,
  style,
  label,
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const handleValueSelect = (val: string) =>
    onChange(
      value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
    );

  const handleValueRemove = (val: string) =>
    onChange(value.filter((v) => v !== val));

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = data.map((item) => (
    <Combobox.Option value={item} key={item} active={value.includes(item)}>
      <Group gap="sm">
        <Checkbox
          checked={value.includes(item)}
          onChange={() => {}}
          aria-hidden
          tabIndex={-1}
          style={{ pointerEvents: "none" }}
        />
        <span>{item}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          rightSection={
            <Selector size={20} color="#506176" strokeWidth={1.5} />
          }
          pointer
          onClick={() => combobox.toggleDropdown()}
          style={style}
        >
          <Pill.Group>
            {values.length > 0 ? (
              value.includes("Все") ? (
                "Все"
              ) : (
                `Выбрано: ${values.length}`
              )
            ) : (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
