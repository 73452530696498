import { useState } from "react";
import { Button } from "./button";
import { Icon } from "./icon";

type Props = {
  maxValue: number;
  value: number;
  setTotalPrice: (price: number) => void;
  itemPrice: number;
  totalPrice: number;
  productList: {
    id: string;
    quantity: number;
  }[] | null;
  setProductList: (list: { id: string; quantity: number }[] | null) => void;
  productId: string;
};

export const QuantitySelector = ({
  maxValue,
  value,
  setTotalPrice,
  itemPrice,
  totalPrice,
  productList,
  setProductList,
  productId,
}: Props) => {
  const [quantity, setQuantity] = useState(value);

  const updateQuantity = (newQuantity: number) => {
    const clampedQuantity = Math.max(1, Math.min(newQuantity, maxValue));
    const priceDifference = (clampedQuantity - quantity) * itemPrice;

    setQuantity(clampedQuantity);
    setTotalPrice(totalPrice + priceDifference);

    if (productList) {
      const updatedProductList = productList.map((product) =>
        product.id === productId
          ? { ...product, quantity: clampedQuantity }
          : product
      );
      setProductList(updatedProductList);
    }
  };

  const handleInput = (str: string) => {
    let n = Number(str);
    if (!isNaN(n) && n > 0) {
      updateQuantity(n);
    }
  };

  return (
    <div
      className="flex gap-2 items-center basefont-b1 bg-bg-light-gray rounded-xl px-4"
      style={{ width: "144px" }}
    >
      <Button
        variant="action"
        size="icon"
        onClick={() => updateQuantity(quantity - 1)}
        disabled={quantity === 1}
      >
        <Icon name="minus" className="w-[18px] h-[18px]" />
      </Button>

      <span className="w-full flex justify-center">
        <input
          value={quantity}
          className="w-fit outline-none bg-transparent text-center"
          onChange={(e) => handleInput(e.target.value)}
          style={{ width: "50px" }}
        />
      </span>

      <Button
        variant="action"
        size="icon"
        onClick={() => updateQuantity(quantity + 1)}
        disabled={quantity === maxValue}
      >
        <Icon name="plus" className="w-[18px] h-[18px]" />
      </Button>
    </div>
  );
};
