import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import { getBanners } from "../../lib/api/banner";
import { Banner, Banners } from "../../lib/api/types/banners";
import Paginator from "../../shared/paginator";
import { format } from "date-fns";

interface BannerTableProps {
  setID: (id: string) => void;
  openModal: () => void;
  setModalTitle: (title: string) => void;
  updateData: boolean;
  openedSideBar: boolean;
}

const BannerTable: React.FC<BannerTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [data, setData] = useState<Banners[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchBanners = async () => {
    setLoading(true);
    const offset = (activePage - 1) * selectedRowsNumber;
    const activeStatus =
      selectedStatus?.includes("Все") || selectedStatus === null
        ? [false, true]
        : selectedStatus?.map((v) => v === "Активен");

    let created_from = null;
    let created_to = null;

    if (dateValue[0]) {
      const startDate = new Date(dateValue[0]);
      startDate.setHours(0, 0, 0, 0);
      created_from = startDate.toISOString();

      if (dateValue[1]) {
        const endDate = new Date(dateValue[1]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      } else {
        const endDate = new Date(dateValue[0]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      }
    }

    const response = await getBanners({
      search: searchId,
      active: activeStatus,
      created_from,
      created_to,
      offset,
      limit: selectedRowsNumber,
    });

    setData(response.banners.sort((a, b) => (a.active && !b.active ? -1 : 1)));
    setTotal(response.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, selectedStatus, dateValue, props.updateData, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    setPage(1);
    fetchBanners();
  };

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Активен", "Не активен"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ width: "704px", marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` : '',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID баннера",
    "Название",
    "Тип",
    "Дата создания",
    "Статус активности",
  ];

  const headerDataKeyMap = {
    "ID баннера": "idBanner",
    "Название": "name",
    "Тип": "type",
    "Дата создания": "creationDate",
    "Статус активности": "status",
  };

  const clickableHeaders = ["Название"];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    return status === "Активен"
      ? {
        backgroundColor: "#DEF7F0",
        borderRadius: 16,
        padding: "8px 16px 8px 16px",
        fontWeight: 500,
      }
      : {
        backgroundColor: "#FDE3E3",
        borderRadius: 16,
        padding: "8px 16px 8px 16px",
        fontWeight: 500,
      };
  };

  const handleRowClick = (banner: Banner) => {
    props.setID(banner.id);
    props.openModal();
    props.setModalTitle("Редактировать баннер");
  };

  const formattedData = data.map((banner) => ({
    idBanner: banner.id.toString(),
    name: banner.name,
    type:
      banner.type === "small"
        ? "маленький"
        : banner.type === "medium"
          ? "средний"
          : banner.type === "big"
            ? "большой"
            : banner.type,
    creationDate: format(new Date(banner.created), "dd.MM.yyyy"),
    status: banner.active ? "Активен" : "Не активен",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="banner"
          customData={data}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default BannerTable;
