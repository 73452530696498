import { useState } from "react";
import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useDisclosure } from "@mantine/hooks";
import EmployeeTable from "../components/employees/employeeTable";
import EmployeeModal from "../components/employees/employeeModal";



const Employees = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Добавить сотрудника");
  const [ID, setID] = useState<string | null>(null);
  const [updateData, setUpdateData] = useState<boolean>(false);

  const handleCreateEmployeeClick = () => {
    setModalTitle("Добавить сотрудника");
    setID(null)
    open();
  };

  return (
    <>
      <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
        <Group style={{ paddingLeft: 0 }}>
          <h1 className="basefont-h1">Сотрудники</h1>
        </Group>
        <Group>
          <Button
            size="md"
            radius="md"
            style={{
              backgroundColor: "#0560BE",
              borderRadius: 12,
              fontSize: 15,
              marginBottom: 10,
            }}
            onClick={handleCreateEmployeeClick}
          >
            + Добавить сотрудника
          </Button>
        </Group>
      </div>
      {opened && (
        <EmployeeModal
          opened={opened}
          close={close}
          id={ID}
          modalTitle={modalTitle}
          setUpdateData={setUpdateData}
        />
      )}
      <EmployeeTable
        setID={setID}
        openModal={open}
        updateData={updateData}
        setModalTitle={setModalTitle}
      />
    </>
  );
};

export default Employees;
