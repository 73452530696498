import React, { useState } from 'react';
import { Group, MultiSelect, CloseButton } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { Button as CustomButton } from '../../shared/button';

interface WorkingHoursProps {
    fieldStyles: any;
    onChange: (workingHours: { days: number[]; from: string; to: string }[]) => void;
    allDays: { value: number, label: string }[]
}

const WorkingHours: React.FC<WorkingHoursProps> = ({ fieldStyles, onChange, allDays }) => {
    const [workingHours, setWorkingHours] = useState([{ days: [], from: "", to: "" }]);

    const handleAddHours = () => {
        const newHours = [...workingHours, { days: [], from: "", to: "" }];
        setWorkingHours(newHours);
        onChange(newHours);
    };

    const handleHoursChange = (index: number, field: keyof typeof workingHours[number], value: any) => {
        const updatedHours = [...workingHours];
        updatedHours[index][field] = value;
        setWorkingHours(updatedHours);
        onChange(updatedHours);
    };

    const handleDaysChange = (index: number, value: string[]) => {
        const updatedDays = value.map(v => parseInt(v, 10));
        const updatedHours = [...workingHours];
        updatedHours[index].days = updatedDays as never[];
        setWorkingHours(updatedHours);
        onChange(updatedHours);
    };

    const handleDeleteHours = (index: number) => {
        const updatedHours = workingHours.filter((_, i) => i !== index);
        setWorkingHours(updatedHours);
        onChange(updatedHours);
    };

    const getAvailableDays = (index: number) => {
        const selectedDays: number[] = workingHours.reduce<number[]>((acc, wh, i) => {
            if (i !== index) {
                return acc.concat(wh.days);
            }
            return acc;
        }, []);

        return allDays.filter((day) => !selectedDays.includes(day.value)).map(day => ({
            value: day.value.toString(),
            label: day.label,
        }));
    };

    return (
        <div style={{ borderRadius: '12px', padding: '16px' }}>
            <h2 className="basefont-h2 mb-[24px]">Часы работы</h2>
            {workingHours.map((hoursEntry, index) => (
                <Group key={`hours-${index}`} className="mb-4">
                    <MultiSelect
                        label="Дни недели"
                        placeholder="Выберите дни недели"
                        data={getAvailableDays(index)}
                        radius="md"
                        size="md"
                        style={{ maxWidth: '100%', width: '30%' }}
                        value={hoursEntry.days.map(String)}
                        onChange={(value) => handleDaysChange(index, value)}
                        styles={{
                            input: {
                                ...fieldStyles.input,
                                height: 'auto',
                            },
                        }}

                    />
                    <TimeInput
                        label="Время начала"
                        placeholder="Введите время начала"
                        value={hoursEntry.from}
                        onChange={(event) => handleHoursChange(index, 'from', event.currentTarget.value)}
                        styles={fieldStyles}
                    />
                    <TimeInput
                        label="Время окончания"
                        placeholder="Введите время окончания"
                        value={hoursEntry.to}
                        onChange={(event) => handleHoursChange(index, 'to', event.currentTarget.value)}
                        styles={fieldStyles}
                    />
                    <CloseButton
                        onClick={() => handleDeleteHours(index)}
                        size="lg"
                        style={{
                            backgroundColor: '#ff4d4f',
                            color: '#ffffff',
                            border: 'none',
                            marginTop: '18px',
                        }}
                    />
                </Group>
            ))}
            <CustomButton
                variant="outline"
                className="border-default-blue w-[648px]"
                onClick={handleAddHours}
            >
                Добавить график работы в другие дни
            </CustomButton>
        </div>
    );
};

export default WorkingHours;
