import React from "react";
import { Button, Modal } from "@mantine/core";
import { useNavigate } from "react-router-dom";

interface ConfirmExitModalProps {
  opened: boolean;
  close: () => void;
}

const ConfirmExitModal: React.FC<ConfirmExitModalProps> = ({
  opened,
  close,
}) => {
  const navigate = useNavigate();

  const handleExit = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    close();
    navigate("/login");
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title="Вы точно хотите выйти?"
      centered
      className="personal-model"
    >
      <Button
        onClick={close}
        fullWidth
        style={{
          backgroundColor: "#ffffff",
          color: "#0560BE",
          width: "410px",
          height: "48px",
          padding: "8px, 20px, 8px, 20px",
          marginLeft: "10px",
          marginTop: "12px",
        }}
      >
        Отменить
      </Button>
      <Button
        onClick={handleExit}
        fullWidth
        style={{
          backgroundColor: "#0560BE",
          color: "#ffffff",
          width: "410px",
          height: "48px",
          padding: "8px, 20px, 8px, 20px",
          marginLeft: "10px",
          marginTop: "12px",
        }}
      >
        Да, выйти
      </Button>
    </Modal>
  );
};

export default ConfirmExitModal;
