import { ChakraProvider } from "@chakra-ui/react";
import Shell from "../shell";
import "./main-pages.css";

function Main() {
  return (
    <ChakraProvider>
      <Shell />
    </ChakraProvider>
  )
}

export default Main;
