import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";
import OrdersTable from "../components/orders/order-tables";
import EditOrder from "../components/orders/edit-order";
import { Order } from "../lib/api/types/orders";

function Orders({ openedSideBar }: { openedSideBar: boolean }) {
  const [editOrder, setEditOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState<number | null>(null);

  const edit = (value: boolean, order: Order | null) => {
    setEditOrder(value);
    setOrderNumber(order?.number ? order.number : null );
  };

  return (
    <>
      {editOrder ? (
        <EditOrder editOrder={edit} orderNumber={orderNumber} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Заказы</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={() => {
                  setEditOrder(true);
                  setOrderNumber(null);
                }}
              >
                + Создать заказ
              </Button>
            </Group>
          </div>
          <OrdersTable
            editOrder={edit}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
}

export default Orders;
