import { Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";
import EDOForm from "../components/edo/EDOForm";
import EDOTable from "../components/edo/EDOTable";

function EDOPage() {
  const [closeEDO, setCloseEDO] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const handleCloseEDO = (value: boolean) => {
    setCloseEDO(value);
  };

  return (
    <>
      {closeEDO ? (
        <EDOForm closeEDO={handleCloseEDO} id={id} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">ЭДО</h1>
            </Group>
          </div>

          <EDOTable setId={setId} openForm={handleCloseEDO} />
        </>
      )}
    </>
  );
}

export default EDOPage;
