import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { Search } from "tabler-icons-react";
import TablePattern from "../table-pattern";
import { EDO } from "../../lib/api/types/edo";
import { getEDOs } from "../../lib/api/edo";
import Paginator from "../../shared/paginator";

const statusTranslations: { [key: string]: string } = {
  processing: "В обработке",
  connected: "Подключен",
};

const statusStyles: { [key: string]: React.CSSProperties } = {
  Подключен: {
    backgroundColor: "#DEF7F0",
    borderRadius: 16,
    padding: "8px 16px 8px 16px",
    fontWeight: 500,
  },
  "В обработке": {
    backgroundColor: "#FDF6E3",
    borderRadius: 16,
    padding: "8px 16px 8px 16px",
    fontWeight: 500,
    border: "1px solid #E0E0E0",
  },
};

const statusToBackend: { [key: string]: string } = {
  "В обработке": "processing",
  Подключен: "connected",
};

interface EDOTableProps {
  setId: (id: string) => void;
  openForm: (value: boolean) => void;
}

const EDOTable: React.FC<EDOTableProps> = (props) => {
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);

  const [total, setTotal] = useState<number>(25);
  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EDO[]>([]);

  const fetchEDO = async () => {
    setLoading(true);
    const offset = (activePage - 1) * selectedRowsNumber;
    let backendStatuses = null;
    if (selectedStatus && !selectedStatus.includes("Все")) {
      backendStatuses = selectedStatus.map((status) => statusToBackend[status]);
    }
    const response = await getEDOs({
      search: searchId,
      statuses: backendStatuses,
      offset: offset,
      limit: selectedRowsNumber,
    });
    setLoading(false);
    setData(response.edm);
    setTotal(response.total);
  };

  useEffect(() => {
    fetchEDO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, selectedStatus, activePage, selectedRows]);

  const handleResetFilters = () => {
    setSearchId("");
    setSelectedStatus(null);
    fetchEDO();
  };

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Подключен", "В обработке"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 4, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус модерации"
        data={["Все", "Подключен", "В обработке"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          marginTop: "26px",
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID клиента",
    "Наименование организации",
    "ИНН",
    "Идентификатор ЭДО",
    "Система",
    "Статус",
  ];

  const headerDataKeyMap = {
    "ID клиента": "idClient",
    "Наименование организации": "companyName",
    "ИНН": "inn",
    "Идентификатор ЭДО": "organizationId",
    "Система": "SED",
    "Статус": "status",
  };

  const clickableHeaders = ["Наименование организации"];

  const formattedData = data.map((edo) => {
    const translatedStatus = statusTranslations[edo.status] || edo.status;
    return {
      idClient: edo.user.id.toString(),
      companyName: edo.company_name,
      inn: edo.organizationIIN,
      organizationId: edo.organizationID,
      SED: edo.identifier,
      status: translatedStatus,
    };
  });

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    return statusStyles[status] || {};
  };

  const handleRowClick = (edo: EDO) => {
    props.setId(edo.organizationID);
    props.openForm(true);
  };

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="edo"
          customData={data}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default EDOTable;
