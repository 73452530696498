import { useState } from "react";
import { Button, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CollectionForm from "../components/collection/collectionForm";
import CollectionTable from "../components/collection/collectionTable";
import "./main-pages.css";
function Collection({ openedSideBar }: { openedSideBar: boolean }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Создание подборки");
  const [id, setID] = useState<string | null>(null);

  const handleCreateCollectionClick = () => {
    setModalTitle("Создание подборки");
    setID(null);
    open();
  };

  return (
    <>
      {opened ? (
        <CollectionForm title={modalTitle} id={id} close={close} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Подборки товаров</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={handleCreateCollectionClick}
              >
                + Создать подборку
              </Button>
            </Group>
          </div>
          <CollectionTable
            setID={setID}
            openModal={open}
            setModalTitle={setModalTitle}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
}

export default Collection;
