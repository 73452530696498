import apiClient from './base';
import { Admin, AdminResponse } from './types/admin';

export const getAdmin = async (): Promise<Admin> => {
    const response = await apiClient.get<AdminResponse>('/admins/me');
    const adminData = response.data.admin;

    const roleMap: Record<string, string> = {
        super_admin: 'Aдминистратор',
        manager: 'Mенеджер ИМ',
        content_manager: 'Kонтент-менеджер',
    };

    return {
        ...adminData,
        role: roleMap[adminData.role] || adminData.role,
    };
};

export const updateAdminPassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    return await apiClient.post("/admins/me/change-password", {
        old_password: oldPassword,
        new_password: newPassword
    });
}
