import React, { useState, useEffect } from "react";
import { Button, TextInput, Card } from "@mantine/core";
import { Search } from "tabler-icons-react";
import { Store } from "../../lib/api/types/store";

interface StoreComponentProps {
  store: Store | null;
  stores: Store[] | undefined;
  setSelectedStore: React.Dispatch<React.SetStateAction<any>>;
}

const StoreComponent: React.FC<StoreComponentProps> = ({ store, stores, setSelectedStore }) => {
  const [address, setAddress] = useState(store?.address || "");
  const [selectedStore, setSelectedStoreState] = useState<Store | null>(null);
  const [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    if (store) {
      setAddress(store.address);
    }
  }, [store]);

  const handleSelectStore = (store: Store) => {
    setSelectedStore(store);
    setSelectedStoreState(store);
    setAddress(store.address);
    setListVisible(false);
  };

  const handleResetFilters = () => {
    setSelectedStore(null);
    setSelectedStoreState(null);
    setAddress("");
    setListVisible(true);
  };

  const filteredStores = stores?.filter((store) =>
    store.address.toLowerCase().includes(address.toLowerCase())
  );

  return (
    <div>
      <h2 className="basefont-h2 mb-8 mt-4">Выбор магазина для самовывоза</h2>
      <div style={{ display: "flex" }}>
        <TextInput
          style={{ flex: 1, width: "1318px" }}
          value={address}
          onFocus={() => setListVisible(true)}
          onChange={(e) => {
            setAddress(e.currentTarget.value);
            setListVisible(true);
          }}
          placeholder="Введите адрес магазина"
          leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
          size="md"
          radius="md"
        />
        <Button
          onClick={handleResetFilters}
          size="md"
          radius="sm"
          style={{
            backgroundColor: "white",
            color: "#25262B",
            borderRadius: 12,
            fontSize: 15,
            width: "120px",
          }}
        >
          Сбросить
        </Button>
      </div>

      {listVisible && (
        <div
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            marginTop: "16px",
            border: "1px solid #ccc",
            padding: "8px",
          }}
        >
          {filteredStores?.map((store) => (
            <div
              key={store.store_id}
              onClick={() => handleSelectStore(store)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderRadius: "8px",
                paddingBottom: "12px",
                backgroundColor:
                  selectedStore?.store_id === store.store_id ? "#f0f0f0" : "transparent",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#e6f7ff")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#f9f9f9")
              }
            >
              <strong>{store.name}</strong>
            </div>
          ))}
        </div>
      )}
      {selectedStore && (
        <Card shadow="sm" padding="lg" radius="md" style={{ marginTop: "20px" }}>
          <h3>Адрес выбранного магазина:</h3>
          <p>{selectedStore.address || "Адрес не указан."}</p>
        </Card>
      )}
    </div>
  );
};

export default StoreComponent;
