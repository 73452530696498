import { Button, MantineProvider } from "@mantine/core";
import { useEffect, useState } from "react";
import { ChevronLeft } from "tabler-icons-react";
import TablePattern from "../table-pattern";
import { fetchCartDetails, fetchCartProducts } from "../../lib/api/carts";
import { Cart, Product } from "../../lib/api/types/carts";
import { format } from "date-fns";
import { formatPhone, formatRuble } from "../../lib/utils";
import Paginator from "../../shared/paginator";

const AbandonedCartDetails = ({
  cartId,
  closeDetails,
}: {
  cartId: string;
  closeDetails: () => void;
}) => {
  const [cart, setCart] = useState<Cart | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState<number>(25);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const cartResponse = await fetchCartDetails(cartId);
      setCart(cartResponse.cart);
      const offset = (activePage - 1) * selectedRowsNumber;
      const productsResponse = await fetchCartProducts(cartId, { offset: offset, limit: selectedRowsNumber });
      setProducts(productsResponse.products);
      setTotal(productsResponse.total);
      setLoading(false);
    };

    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, activePage, selectedRows]);

  const clientHeaders = [
    "ID клиента",
    "Клиент",
    "Номер телефона",
    "Дата создания",
    "E-mail",
    "Сумма корзины",
    "Кол-во поз-й",
  ];

  const clientHeaderDataKeyMap = {
    "ID клиента": "clientId",
    "Клиент": "clientName",
    "Номер телефона": "phoneNumber",
    "Дата создания": "creationDate",
    "E-mail": "email",
    "Сумма корзины": "cartTotal",
    "Кол-во поз-й": "positionsCount",
  };

  const clientData = cart
    ? [
      {
        clientId: cart.user.id.toString(),
        clientName:
          cart.user.first_name +
          " " +
          cart.user.last_name +
          " " +
          cart.user.father_name,
        phoneNumber: cart.user.phone ? formatPhone(cart.user.phone) : "",
        creationDate: format(new Date(cart.modified), "dd.MM.yyyy"),
        email: cart.user.email,
        cartTotal: formatRuble(cart.price_total),
        positionsCount: cart.positions_count.toString(),
      },
    ]
    : [];

  const productHeaders = [
    "Артикул",
    "Наименование",
    "Цена товара",
    "Дата добавления",
    "Количество",
    "Тег",
  ];

  const productHeaderDataKeyMap = {
    "Артикул": "productId",
    "Наименование": "name",
    "Цена товара": "price",
    "Дата добавления": "addedDate",
    "Количество": "quantity",
    "Тег": "tag",
  };

  const productData = products.map((product) => ({
    productId: product.code.toString(),
    name: product.name,
    price: formatRuble(product.price),
    addedDate: format(new Date(product.added), "dd.MM.yyyy"),
    quantity: product.quantity.toString(),
    tag: product.is_available ? "В наличии" : "Товар закончился",
  }));

  const getFieldToStyle = () => "tag";

  const getTagStyle = (tag: string) => {
    switch (tag) {
      case "В наличии":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px",
          fontWeight: 500,
        };
      case "Товар закончился":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const clickableHeaders = [""];
  const handleRowClick = () => { };

  return (
    <MantineProvider>
      <div>
        <Button
          leftSection={<ChevronLeft size={20} />}
          onClick={closeDetails}
          style={{
            backgroundColor: "#ffffff",
            color: "#25262B",
            fontSize: "16px",
            padding: 0,
            border: "#ffffff",
            transition: "background-color 0.3s",
            marginBottom: "2%",
          }}
        >
          Назад
        </Button>
        <h1 className="basefont-h1">Брошенная корзина</h1>
        <TablePattern
          headers={clientHeaders}
          data={clientData}
          dataKeyAccessor={clientHeaderDataKeyMap}
          clickableHeaders={clickableHeaders}
          onRowClick={handleRowClick}
          loading={loading}
          activePage={0}
          customDataType="abandonedCart"
          selectedRow={"0"}
        />
        <h2 className="basefont-h2 mt-4">Товары</h2>
        <TablePattern
          headers={productHeaders}
          data={productData}
          dataKeyAccessor={productHeaderDataKeyMap}
          getFieldToStyle={getFieldToStyle}
          getStyle={getTagStyle}
          clickableHeaders={clickableHeaders}
          onRowClick={handleRowClick}
          loading={loading}
          customDataType="abandonedCart"
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default AbandonedCartDetails;
