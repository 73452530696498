import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";

import PromotionForm from "../components/promotions/promotionForm";
import PromotionTable from "../components/promotions/promotion-table";
import { News } from "../lib/api/types/news";



function PromotionsAndNews({ openedSideBar }: { openedSideBar: boolean }) {

  const [editNews, setEditNews] = useState(false);
  const [newsId, setNewsId] = useState<string | null>(null);
  const [title, setTitle] = useState("Создание акции или новости");

  const edit = (value: boolean, News: News | null) => {
    setEditNews(value);
    if (News) {
      setNewsId(News.id);
      setTitle("Редактирование акции или новости");
    }
  };
  const handleCreateNewsClick = () => {
    setEditNews(true);
    setTitle("Создание акции или новости");
    setNewsId(null);
  };
  return (
    <>
      {editNews ? (
        <PromotionForm title={title} editNews={edit} id={newsId} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Акции и новости</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={handleCreateNewsClick}
              >
                + Создать акцию
              </Button>
            </Group>
          </div>
          <PromotionTable
            onRowClick={(article: News) => edit(true, article)}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
}

export default PromotionsAndNews;
