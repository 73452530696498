import { Button, Group, Select, Card, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChevronLeft } from "tabler-icons-react";
import { Button as CustomButton } from "../../shared/button";
import { useEffect, useState } from "react";
import { getEDOById, updateEDO } from "../../lib/api/edo";
import { useToast } from "@chakra-ui/react";

const statusToBackend: { [key: string]: string } = {
  "В обработке": "processing",
  Подключен: "connected",
};

const statusFromBackend: { [key: string]: string } = {
  processing: "В обработке",
  connected: "Подключен",
};

interface EDOFormValues {
  idClient: string;
  phoneNumber: string;
  company_name: string;
  INN: string;
  edoIdentifier: string;
  EDOSystem: string;
  status: string;
}

interface EDOFormProps {
  closeEDO: (value: boolean) => void;
  id: string;
}

const EDOForm: React.FC<EDOFormProps> = ({ closeEDO, id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<EDOFormValues>({
    initialValues: {
      idClient: "",
      phoneNumber: "",
      INN: "",
      edoIdentifier: "",
      EDOSystem: "",
      status: "",
      company_name: "",
    },
  });

  const toast = useToast();

  useEffect(() => {
    const fetchEDO = async () => {
      if (id) {
        setLoading(true);
        try {
          const edo = await getEDOById(id);
          form.setValues({
            idClient: edo.user.id,
            phoneNumber: edo.user.phone,
            INN: edo.organizationIIN,
            edoIdentifier: edo.identifier,
            EDOSystem: edo.system,
            status: statusFromBackend[edo.status],
            company_name: edo.company_name
          });
        } catch (error) {
          console.error("Не удалось получить данные ЭДО:", error);
          toast({
            title: "Ошибка",
            description: "Ошибка сервера.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEDO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    closeEDO(false);
  };

  const handleSubmit = async (values: EDOFormValues) => {
    setIsSubmitting(true);
    try {
      await updateEDO(id, statusToBackend[values.status]);
      toast({
        title: "Успех",
        description: "Статус ЭДО успешно обновлен.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        closeEDO(false)
      }, 1300);
    } catch (error) {
      console.error("Не удалось обновить статус ЭДО:", error);
      toast({
        title: "Ошибка",
        description: "Ошибка сервера.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        className="back-button"
        leftSection={<ChevronLeft size={20} />}
        onClick={handleCancel}
        style={{
          backgroundColor: "#ffffff",
          color: "#25262B",
          fontSize: "16px",
          padding: 0,
          border: "#ffffff",
          transition: "background-color 0.3s",
          marginBottom: "2%",
        }}disabled={isSubmitting}
      >
        Назад
      </Button>

      {loading ? (
        <Loader size="lg" />
      ) : (
        <div className="flex flex-col gap-6">
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <h1 className="basefont-h1">Редактирование статуса ЭДО</h1>
            <CustomButton
              onClick={() => form.onSubmit(handleSubmit)()}
              style={{
                color: "#ffffff",
                backgroundColor: "#0560BE",
                width: "200px",
              }}disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader size="sm" color="white" /> {/* Индикатор загрузки */}
                    <span style={{ marginLeft: 8 }}>Сохраняется...</span>
                  </>
                ) : (
                  "Сохранить"
                )}
            </CustomButton>
          </Group>

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2 space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <div className="mb-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="font-medium text-md">ID клиента</div>
                      <span>{form.values.idClient}</span>
                    </div>
                    <div>
                      <div className="font-medium text-md">Система</div>
                      <span>{form.values.EDOSystem}</span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="font-medium text-md">Номер телефона</div>
                      <span>{form.values.phoneNumber}</span>
                    </div>
                    <div>
                      <div className="font-medium text-md">
                        Идентификатор ЭДО
                      </div>
                      <span>{form.values.edoIdentifier}</span>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="font-medium text-md">ИНН</div>
                      <span>{form.values.INN}</span>
                    </div>
                    <div>
                      <div className="font-medium text-md">Наименование организации</div>
                      <span>{form.values.company_name}</span>
                    </div>
                  </div>
                </div>

              </Card>
            </div>

            <div className="space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <h2 className="basefont-h2">Статус</h2>
                <Select
                  label="Статус активности"
                  placeholder="Выберите статус"
                  data={["Подключен", "В обработке"]}
                  styles={{
                    input: {
                      borderRadius: "12px",
                    },
                  }}
                  {...form.getInputProps("status")}
                />
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EDOForm;
