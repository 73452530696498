import {
  Group,
  TextInput,
  Radio,
  Button as MantineButton,
  Select,
  Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { ChevronLeft } from "tabler-icons-react";
import { useEffect, useState } from "react";
import StoreComponent from "./StoreComponent";
import DeliveryComponent from "./DeliveryComponent";
import PickupPointComponent from "./PickupPointComponent";
import { Button } from "../../shared/button";
import { DeliveryDetails, Item, Order, PickUpPoint } from "../../lib/api/types/orders";
import axiosInstance from "../../lib/api/base";
import { getOrderById, preOrderBonuses, preOrderDates, preOrderPickUpPoints } from "../../lib/api/orders";
import { getUserById, getUserByPhone, registerUser } from "../../lib/api/user";
import ProductsTable from "./product-table";
import { formatRuble } from "../../lib/utils";
import { Store } from "../../lib/api/types/store";
import { getStores } from "../../lib/api/stores";

interface EditOrderProps {
  editOrder: (value: boolean, order: Order | null) => void;
  orderNumber: number | null;
}

const PHONE_REGEX = /^[0-9]+$/;
const DEFAULT_PREFIX = "+7";

const statusTranslations: { [key: string]: string } = {
  confirmation_waiting: "Ожидает подтверждения",
  payment_waiting: "Ожидает оплаты",
  paid: "Оплачен",
  preparing: "Сборка",
  sent_for_delivery: "Передан в доставку",
  ready_for_receiving: "Готов к получению",
  received: "Получен",
  cancelled: "Отменен",
};


const statusTranslationsReverse: { [key: string]: string } = {
  "Ожидает подтверждения": "confirmation_waiting",
  "Ожидает оплаты": "payment_waiting",
  "Оплачен": "paid",
  "Сборка": "preparing",
  "Передан в доставку": "sent_for_delivery",
  "Готов к получению": "ready_for_receiving",
  "Получен": "received",
  "Отменен": "cancelled",
};

const EditOrder: React.FC<EditOrderProps> = ({ editOrder, orderNumber }) => {
  const selectedHeaders = [
    "Артикул",
    "Наименование",
    "Стоимость",
    "Количество",
    "Сумма",
    "Действие",
  ];

  const selectHeaders = [
    "Артикул",
    "Наименование",
    "Стоимость",
    "Действие",
  ];

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [attemptId, setAttemptId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState<Item[] | null>(null);
  const [sendCodeDisabled, setSendCodeDisabled] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const toast = useToast()
  const [phoneIsVerified, setPhoneIsVerified] = useState(false)
  const [deliveryMethod, setDeliveryMethod] = useState<string>("store");
  const [payment_method, setPaymentMethod] = useState<string>("card")
  const [store, setStore] = useState<Store>()
  const [stores, setStores] = useState<Store[]>();
  const [pickUpPoint, setPickUpPoint] = useState<PickUpPoint>()
  const [pickUpPoints, setPickUpPoints] = useState<PickUpPoint[]>()
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryDetails>()
  const [authType, setAuthType] = useState<string>("signin")
  const [signedIn, setSignedIn] = useState<boolean>(false)
  // const [availableToDebitBonuses, setAvailableToDebitBonuses] = useState<number>(0)
  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      code: "",
      orderNumber: 0,
      created: "",
      status: "",
      user_id: "",
      receive_type: "",
      receive: "",
      payment_method: "",
      receipt_url: null,
      price_delivery: 0,
      discount_bonuses: 0,
      debit_bonuses: 0,
      total_price: 0,
      bonuses_added: 0,
      bonuses_spent: 0,
      comment: "",
    },
    validate: {
      first_name: (value) => {
        return value.trim()
          ? /^[a-zA-Zа-яА-Я]+$/.test(value)
            ? null
            : "Имя может содержать только буквы"
          : "Имя обязательно";
      },
      last_name: (value) => {
        return value.trim()
          ? /^[a-zA-Zа-яА-Я]+$/.test(value)
            ? null
            : "Фамилия может содержать только буквы"
          : "Фамилия обязательна";
      },
      email: (value) => {
        return /^\S+@\S+\.\S+$/.test(value)
          ? null
          : "Некорректный email";
      },
      phone: (value) =>
        !PHONE_REGEX.test(value.replace("+", ""))
          ? "Номер телефона может содержать только цифры"
          : null,
      code: (value) =>
        value.length === 4 && PHONE_REGEX.test(value)
          ? null
          : "Введите 4 цифры",
    },
  });

  useEffect(() => {
    if (orderNumber) {
      const fetchOrder = async () => {
        try {
          setLoading(true);

          const [order, allStores] = await Promise.all([
            getOrderById(orderNumber),
            getStores({
              search: "",
              added_from: null,
              added_to: null,
              cities: null,
              active: [true],
              offset: 0,
              limit: 250,
            }),
          ]);

          const productList = order.order.products.map(({ id, quantity }) => ({ id, quantity }));
          setProductList(productList);
          const [user, preOrderStores, preOrderPickUpPointsResponse] = await Promise.all([
            getUserById(order.order.user_id),
            preOrderDates({ user_id: order.order.user_id, items: productList }),
      //      preOrderBonuses({ user_id: order.order.user_id, items: productList, debit_bonuses: 0 }),
            preOrderPickUpPoints({ user_id: order.order.user_id, items: productList }),
          ]);
          //     setAvailableToDebitBonuses(preOrderBonusesResponse.available_to_debit_bonuses)
          const validStoreIds = preOrderStores.receive_dates.store.stores.map(store => store.store_id);
          const filteredStores = allStores.stores.filter(store => validStoreIds.includes(store.store_id));
          setStores(filteredStores);
          setPickUpPoints(preOrderPickUpPointsResponse.pickup_points);
          const { payment_method } = order.order
          if (payment_method === "card") {
            setPaymentMethod("card")
          }
          else if (payment_method === "cash") {
            setPaymentMethod("cash")
          }
          else {
            setPaymentMethod("invoice")
          }

          const { receive } = order.order;
          if (receive.store) {
            const currentStore = filteredStores.find(store => store.store_id === receive.store?.store_id);
            setStore(currentStore);
          } else if (receive.delivery) {
            setDeliveryDetails(receive.delivery)
            setDeliveryMethod("delivery");

          } else if (receive.pickup_point) {
            setDeliveryMethod("pickup_point");
            const pickUpPoint = preOrderPickUpPointsResponse.pickup_points.find(
              point => point.id === receive.pickup_point?.pickup_point_id
            );
            setPickUpPoint(pickUpPoint);
          }
          setTotalPrice(order.order.total_price);
          const { first_name, last_name, email, phone } = user.user;
          form.setValues({
            first_name: `${first_name}`,
            last_name: `${last_name}`,
            email,
            phone,
            orderNumber: order.order.number,
            user_id: order.order.user_id,
            created: new Date(order.order.created).toLocaleDateString("ru-RU"),
            total_price: order.order.total_price,
            status: statusTranslations[order.order.status],
            discount_bonuses: order.order.discount_bonuses,
            price_delivery: order.order.price_delivery,
          });

          setLoading(false);
        } catch (error) {
          console.error("Error fetching order", error);
        }
        finally {
          setLoading(false);
        }
      };
      fetchOrder();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber]);

  const formatPhoneNumber = (value: string) => {
    const digitsOnly = value.replace(/\D/g, "");
    if (!digitsOnly.startsWith("7")) {
      return `${DEFAULT_PREFIX}${digitsOnly}`;
    }
    return `+${digitsOnly}`;
  };

  const handleSendCode = async () => {
    try {
      const response = await axiosInstance.post("/users/verify-phone", {
        phone: form.values.phone.slice(1),
      });
      setAttemptId(response.data.attempt_id);
      toast({
        title: 'Код отправлен.',
        description: "Ваш код был успешно отправлен на указанный номер телефона.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setSendCodeDisabled(true);
      setTimeout(() => {
        setSendCodeDisabled(false);
      }, 10000);


    } catch (error) {
      toast({
        title: 'Ошибка отправки кода.',
        description: "Ошибка сервера.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (!attemptId) return;
      const verifyCode = {
        attempt_id: attemptId,
        code: parseInt(form.values.code),
      }
      await axiosInstance.post("/users/verify-phone-complete", verifyCode);
      toast({
        title: 'Код подтвержден.',
        description: "Ваш код был успешно подтвержден.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      if (authType === "signin") {
        try {
          const user = await getUserByPhone(form.values.phone.slice(1))
          form.setFieldValue("user_id", user.user.id)
          form.setFieldValue("email", user.user.email)
          form.setFieldValue("first_name", user.user.first_name)
          form.setFieldValue("last_name", user.user.last_name)
          setSignedIn(true)
          setAuthType("signin")
        }
        catch {
          toast({
            title: 'Пользователь не найден',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setAuthType("register")
        }
        finally {
          setVerifyButtonDisabled(true);
          setPhoneIsVerified(true)
        }
      }
      setVerifyButtonDisabled(true);
      setPhoneIsVerified(true)
    } catch (error) {
      toast({
        title: 'Ошибка подтверждения кода.',
        description: "Не удалось подтвердить код. Пожалуйста, проверьте код и попробуйте еще раз.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPhoneIsVerified(false)
    }
  };

  const handleCancel = () => {
    form.reset();
    editOrder(false, null);
  };

  const createOrUpdateOrder = async () => {
    try {
      const isValid = form.validate();
      console.log(isValid.errors)
      let userId = form.values.user_id;

      if (!orderNumber) {
        const userPayload = {
          first_name: form.values.first_name,
          last_name: form.values.last_name,
          email: form.values.email,
          phone: form.values.phone.replace("+", ""),
        };

        const userResponse = await registerUser(userPayload);
        userId = userResponse.user.id;
      }

      const orderPayload = {
        user_id: userId,
        receive_type: deliveryMethod,
        receive: {
          store: deliveryMethod === "store" ? { store_id: store?.store_id } : undefined,
          delivery: deliveryMethod === "delivery" ? deliveryDetails : undefined,
          pickup_point: deliveryMethod === "pickup_point" ? { pickup_point_id: pickUpPoint?.id } : undefined,
        },
        items: productList || [],
        payment_method: payment_method,
      };

      if (orderNumber) {
        const updatePayload = {
          ...orderPayload,
          status: statusTranslationsReverse[form.values.status],
        };

        await axiosInstance.put(`/orders/${orderNumber}`, updatePayload);
        toast({
          title: "Заказ успешно обновлен",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

      } else {
        const updatePayload = {
          ...orderPayload,
          debit_bonuses: form.values.debit_bonuses,
        };
        await axiosInstance.post("/orders/create", updatePayload);

        toast({
          title: "Заказ успешно создан",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setTimeout(() => {
        form.reset();
        editOrder(false, null);
      }, 1300);

    } catch (error) {
      console.error("Error in order creation/update", error);
      toast({
        title: "Не удалось создать/обновить заказ",
        description: "Произошла ошибка при создании или обновлении заказа. Пожалуйста, попробуйте еще раз.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeliveryMethodChange = (value: string) => {
    setDeliveryMethod(value);
  };

  const handlePaymentMethodChange = (value: string) => {
    setPaymentMethod(value);
  };

  const handleProduct = async (productId: string, type: string, price: number) => {
    setProductList((prevProducts) => {
      const products = prevProducts || [];

      if (type === "select") {
        const productExists = products.some((product) => product.id === productId);
        if (!productExists) {
          setTotalPrice(totalPrice + price);
          return [...products, { id: productId, quantity: 1 }];
        }
        return products;
      } else if (type === "selected") {
        const updatedProducts = products.filter((product) => product.id !== productId);
        const productToRemove = products.find((product) => product.id === productId);

        if (productToRemove) {
          setTotalPrice(totalPrice - productToRemove.quantity * price);
        }

        return updatedProducts;
      }

      return products;
    });


  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allStores, preOrderStores, preOrderPickUpPointsResponse] = await Promise.all([
          getStores({
            search: "",
            added_from: null,
            added_to: null,
            cities: null,
            active: [true],
            offset: 0,
            limit: 250,
          }),
          preOrderDates({ user_id: form.values.user_id, items: productList ? productList : [] }),
       //   preOrderBonuses({ user_id: form.values.user_id, items: productList ? productList : [], debit_bonuses: 0 }),
          preOrderPickUpPoints({ user_id: form.values.user_id, items: productList ? productList : [] }),
        ]);

        //    setAvailableToDebitBonuses(preOrderBonusesResponse.available_to_debit_bonuses)
        const validStoreIds = preOrderStores.receive_dates.store.stores.map(store => store.store_id);
        const filteredStores = allStores.stores.filter(store => validStoreIds.includes(store.store_id));
        setStores(filteredStores);
        setPickUpPoints(preOrderPickUpPointsResponse.pickup_points);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    if (productList) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  const inputStyles = {
    input: {
      cursor: 'default',
      color: '#25262B',
      backgroundColor: '#f1f3f5',
    },
  };
  return (
    <>
      <ChakraProvider>
        <MantineButton
          className="back-button"
          leftSection={<ChevronLeft size={20} />}
          onClick={handleCancel}
          style={{
            backgroundColor: "#ffffff",
            color: "#25262B",
            fontSize: "16px",
            padding: 0,
            border: "#ffffff",
            transition: "background-color 0.3s",
            marginBottom: "2%",
          }}
        >
          Назад
        </MantineButton>

        <div className="flex flex-col gap-6">
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="basefont-h1">
              {orderNumber ? "Редактирование заказа" : "Создание заказа"}
            </h1>
            <Group>
              <Button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ffffff",
                  color: "#1E2134",
                  width: "200px",
                }}
              >
                Отменить
              </Button>
              <Button
                type="button"
                onClick={createOrUpdateOrder}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#0560BE",
                  width: "200px",
                }}
              >
                Сохранить
              </Button>
            </Group>
          </Group>
          <h2 className="basefont-h2">Информация о клиенте</h2>
          {orderNumber ? (
            <>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="ID заказа"
                  size="md"
                  radius="md"
                  value={form.values.orderNumber}
                  readOnly
                  styles={inputStyles}

                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Дата создания заказа"
                  size="md"
                  radius="md"
                  value={form.values.created}
                  readOnly
                  styles={inputStyles}

                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="ФИО"
                  size="md"
                  radius="md"
                  value={`${form.values.first_name} ${form.values.last_name}`}
                  readOnly
                  styles={inputStyles}

                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Номер телефона"
                  size="md"
                  radius="md"
                  value={form.values.phone}
                  readOnly
                  styles={inputStyles}

                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 0.5 }}
                  label="E-mail"
                  size="md"
                  radius="md"
                  value={form.values.email}
                  readOnly
                  styles={inputStyles}

                />
              </Group>
            </>

          ) : (
            <>
              <Group>
                <Select
                  label="Действие"
                  style={{ flex: 1 }}
                  placeholder="Регистрация"
                  disabled={signedIn}
                  data={["Регистрация", "Bход"]}
                  value={authType === "signin" ? "Bход" : "Регистрация"}
                  size="md"
                  radius="md"
                  onChange={(value) => {
                    if (value === "Регистрация")
                      setAuthType("register")
                    else setAuthType("signin")
                  }
                  }
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Имя"
                  placeholder="Введите имя клиента"
                  size="md"
                  radius="md"
                  disabled={authType === "signin"}
                  {...form.getInputProps("first_name")}
                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="Фамилия"
                  placeholder="Введите фамилию клиента"
                  size="md"
                  radius="md"
                  disabled={authType === "signin"}
                  {...form.getInputProps("last_name")}
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="E-mail"
                  placeholder="Введите e-mail клиента"
                  disabled={authType === "signin"}
                  {...form.getInputProps("email")}
                  size="md"
                  radius="md"
                />
              </Group>
              <Group>
                <TextInput
                  style={{ flex: 1 }}
                  label="Номер телефона"
                  placeholder="Введите номер телефона клиента"
                  {...form.getInputProps("phone")}
                  size="md"
                  radius="md"
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    if ((PHONE_REGEX.test(value.replace("+", "")) || value === "") &&
                      value.length <= 12) {
                      form.setFieldValue("phone", formatPhoneNumber(value));
                    }
                  }}
                  error={form.errors.phone}
                  rightSection={
                    <div
                      onClick={sendCodeDisabled || !form.values.phone ? undefined : handleSendCode}
                      style={{
                        display: "flex",
                        marginRight: "55px",
                        cursor: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? "not-allowed" : "pointer",
                        opacity: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? 0.5 : 1,
                        pointerEvents: sendCodeDisabled || !form.values.phone || form.values.phone.length !== 12 ? "none" : "auto"
                      }}
                    >
                      <span className="text-default-blue w-[100px] basefont-t6">
                        {sendCodeDisabled ? "Подождите..." : "Выслать код"}
                      </span>
                    </div>
                  }
                />
                <TextInput
                  style={{ flex: 1 }}
                  label="Подтверждение номера телефона"
                  placeholder="Введите код из смс"
                  size="md"
                  radius="md"
                  {...form.getInputProps("code")}
                  maxLength={4}
                  onChange={(event) => {
                    const value = event.currentTarget.value;
                    if (
                      (PHONE_REGEX.test(value) || value === "") &&
                      value.length <= 4
                    ) {
                      form.setFieldValue("code", value);
                    }
                  }}
                  error={form.errors.code}
                  rightSection={
                    <div
                      onClick={handleVerifyCode}
                      style={{
                        display: "flex",
                        marginRight: "66px",
                        cursor: !form.values.code || form.values.code.length !== 4 || verifyButtonDisabled ? "not-allowed" : "pointer",
                        opacity: !form.values.code || form.values.code.length !== 4 || verifyButtonDisabled ? 0.5 : 1
                      }}
                    >
                      <span className="text-default-blue w-[100px] basefont-t6">
                        Подтвердить
                      </span>
                    </div>
                  }
                />
              </Group>
            </>
          )}

          <Group style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="basefont-h2">Товары в заказе</h2>
            <h2 className="basefont-h2">Итого: {formatRuble(totalPrice)}</h2>
          </Group>
          <Group>
            <ProductsTable
              headers={selectedHeaders}
              productsList={productList}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              type="selected"
              setProductList={setProductList}
              handleProduct={handleProduct}
            />
          </Group>

          <Group>
            <h2 className="basefont-h2">Добавить товары</h2>
            <ProductsTable
              headers={selectHeaders}
              productsList={productList}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              setProductList={setProductList}
              type="select"
              handleProduct={handleProduct}
            />
          </Group>
          <h2 className="basefont-h2">Способ получения заказа</h2>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio.Group
              value={deliveryMethod}
              onChange={handleDeliveryMethodChange}
            >
              <Group
                mt="xs"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <Radio variant="outline" value="store" label="Самовывоз" />
                <Radio variant="outline" value="delivery" label="Доставка" />
                <Radio variant="outline" value="pickup_point" label="Пункт выдачи" />
              </Group>
            </Radio.Group>
            {deliveryMethod === "store" && (
              <StoreComponent store={store ? store : null} stores={stores} setSelectedStore={setStore} />
            )}
            {deliveryMethod === "delivery" && (
              <DeliveryComponent setDeliveryDetails={setDeliveryDetails} deliveryDetails={deliveryDetails ? deliveryDetails : null} />
            )}
            {deliveryMethod === "pickup_point" && (
              <PickupPointComponent pickUpPoint={pickUpPoint ? pickUpPoint : null} pickUpPoints={pickUpPoints} setSelectedPickupPoint={setPickUpPoint} price_delivery={form.values.price_delivery} />
            )}
          </Group>
          <h2 className="basefont-h2">Способ оплаты</h2>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio.Group
              value={payment_method}
              onChange={handlePaymentMethodChange}
            >
              <Group
                mt="xs"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <Radio variant="outline" value="card" label="Картой онлайн" />
                <Radio variant="outline" value="cash" label="При получении" />
                <Radio variant="outline" value="invoice" label="Долями" />
              </Group>
            </Radio.Group>
          </Group>
          {/* <h2 className="basefont-h2">Бонусы</h2>
          <Group>
            <TextInput
              style={{ width: "648px" }}
              label="Бонусы"
              size="md"
              radius="md"
              value={orderNumber ? form.values.bonuses_spent : form.values.debit_bonuses}
              onChange={(event) => form.setFieldValue('debit_bonuses', parseInt(event.target.value))}
              disabled={!!orderNumber}
              type="number"
              rightSection={
                !orderNumber && (
                  <div
                    style={{ display: "flex", marginRight: "66px" }}
                    onClick={async () => {
                      try {
                        const preOrderBonusesResponse = await preOrderBonuses({
                          user_id: form.values.user_id,
                          items: productList ? productList : [],
                          debit_bonuses: form.values.debit_bonuses
                        });

                        setAvailableToDebitBonuses(preOrderBonusesResponse.available_to_debit_bonuses)
                        toast({
                          title: "Неверная сумма бонусов",
                          description: "Пожалуйста, предоставьте достаточную сумму бонуса",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                      catch {
                        toast({
                          title: "Неверная сумма бонусов",
                          description: "Пожалуйста, предоставьте достаточную сумму бонуса",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      }

                    }}
                  >
                    <span className="text-default-blue w-[87px] cursor-pointer basefont-t6">
                      Применить
                    </span>
                  </div>
                )
              }
            />

            {orderNumber && <span className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-primary">
              списание {form.values.bonuses_added} бонуса
            </span>}
            {!orderNumber && <span className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-primary">
              Будет списание {availableToDebitBonuses} бонуса
            </span>}
          </Group> */}

          {orderNumber && (
            <>
              <h2 className="basefont-h2">Статус заказа</h2>
              <Group>
                <Select
                  style={{ width: "50%" }}
                  label="Статус заказа"
                  data={[
                    "Ожидает подтверждения",
                    "Ожидает оплаты",
                    "Оплачен",
                    "Сборка",
                    "Передан в доставку",
                    "Готов к получению",
                    "Получен",
                    "Отменен"
                  ]}
                  size="md"
                  radius="md"
                  value={form.values.status}
                  onChange={(value) =>
                    form.setFieldValue("status", value as string)
                  }
                />
              </Group>
            </>
          )}

          <Group
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "30px",
            }}
          >
            <h1 className="basefont-h1">
              Общая стоимость: {formatRuble(totalPrice)}
            </h1>
            <Button
              disabled={!phoneIsVerified && !orderNumber}
              onClick={createOrUpdateOrder}
              style={{
                display: "inline-block",
                padding: "8px, 20px, 8px, 20px",
                backgroundColor: "#0560BE",
                color: "#ffffff",
                border: "1px solid #0560BE",
                borderRadius: 12,
                textAlign: "center",
                width: "360px",
                height: "54px",
                cursor: "pointer",
              }}
            >
              {orderNumber ? "Сохранить изменения" : "Создать заказ"}
            </Button>
          </Group>
        </div>
      </ChakraProvider>
    </>

  );
};

export default EditOrder;
