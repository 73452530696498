import { useState } from "react";
import AbandonedCartDetails from "../components/abandonedCart/AbandonedCartDetails";
import AbandonedCartsTable from "../components/abandonedCart/AbandonedCartsTable";
import { Cart } from "../lib/api/types/carts";

const AbandonedCarts = ({ openedSideBar }: { openedSideBar: boolean }) => {
  const [selectedCartId, setSelectedCartId] = useState<string | null>(null);

  const handleRowClick = (cart: Cart) => {
    setSelectedCartId(cart.user.id);
  };
  const closeDetails = () => {
    setSelectedCartId(null);
  };

  return (
    <div>
      {selectedCartId ? (
        <AbandonedCartDetails
          cartId={selectedCartId}
          closeDetails={closeDetails}
        />
      ) : (
        <div>
          <h1 className="basefont-h1">Брошенные корзины</h1>
          <AbandonedCartsTable
            onRowClick={handleRowClick}
            openedSideBar={openedSideBar}
          />
        </div>
      )}
    </div>
  );
};

export default AbandonedCarts;
