import axiosInstance from './base';
import {
  GetUserByPhoneResponse,
  GetUserByIdResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  VerifyPhoneResponse,
} from './types/user';

export const getUserByPhone = async (phone: string): Promise<GetUserByPhoneResponse> => {
  const response = await axiosInstance.post<GetUserByPhoneResponse>('/users/get-by-phone', { phone });
  return response.data;
};

export const getUserById = async (id: string): Promise<GetUserByIdResponse> => {
  const response = await axiosInstance.post<GetUserByIdResponse>('/users/get-by-id', { id });
  return response.data;
};

export const registerUser = async (user: RegisterUserRequest): Promise<RegisterUserResponse> => {
  const response = await axiosInstance.post<RegisterUserResponse>('/users/register', user);
  return response.data;
};

export const verifyPhone = async (phone: string): Promise<VerifyPhoneResponse> => {
  const response = await axiosInstance.post<VerifyPhoneResponse>('/users/verify-phone', { phone });
  return response.data;
};

export const verifyPhoneComplete = async (attempt_id: string, code: number): Promise<void> => {
  await axiosInstance.post('/users/verify-phone-complete', { attempt_id, code });
};
