import React from "react";
import { Button, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChakraProvider, useToast } from "@chakra-ui/react";
import { updateAdminPassword } from "../../lib/api/admin";

interface ChangePasswordModalProps {
  opened: boolean;
  close: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  opened,
  close,
}) => {
  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      oldPassword: (value) => (value ? null : "Старый пароль обязателен"),
      newPassword: (value) => (value ? null : "Новый пароль обязателен"),
      confirmPassword: (value, values) =>
        value === values.newPassword ? null : "Пароли не совпадают",
    },
  });

  const toast = useToast();

  const handleSubmit = async (values: typeof form.values) => {
    try {
      await updateAdminPassword(values.oldPassword, values.newPassword);
      toast({
        title: 'Успех.',
        description: "Пароль успешно изменен",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      form.reset()
      close();
    } catch (error) {
      console.error("Error changing password:", error);
      toast({
        title: "Ошибка",
        description: "Ошибка сервера.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Modal
        opened={opened}
        onClose={close}
        title="Смена пароля"
        centered
        className="personal-model"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Старый пароль"
            placeholder="Введите текущий пароль"
            style={{ padding: "12px" }}
            {...form.getInputProps("oldPassword")}
          />
          <TextInput
            label="Новый пароль"
            placeholder="Введите новый пароль"
            style={{ padding: "12px" }}
            {...form.getInputProps("newPassword")}
            type="password"
          />
          <TextInput
            label="Повторите новый пароль"
            placeholder="Повторите новый пароль"
            style={{ padding: "12px" }}
            {...form.getInputProps("confirmPassword")}
            type="password"
          />
          <Button
            type="submit"
            style={{
              backgroundColor: "#0560BE",
              width: "410px",
              height: "48px",
              padding: "8px, 20px, 8px, 20px",
              marginLeft: "10px",
              marginTop: "12px",
            }}
          >
            Сохранить
          </Button>
          <Button
            onClick={() => {
              form.reset()
              close()
            }}
            variant="outline"
            style={{
              width: "410px",
              height: "48px",
              padding: "8px, 20px, 8px, 20px",
              marginLeft: "10px",
              marginTop: "12px",
            }}
          >
            Отменить
          </Button>
        </form>
      </Modal>
    </ChakraProvider>
  );
};

export default ChangePasswordModal;
