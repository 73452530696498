import { Group } from "@mantine/core";
import "./main-pages.css";
import { useState } from "react";
import ReviewTable from "../components/reviews/review-table";
import EditReview from "../components/reviews/edit-review";

function Reviews({ openedSideBar }: { openedSideBar: boolean }) {
  const [editReview, setEditReview] = useState(false);
  const [editedReview, setEditedReview] = useState<{
    item_id: string;
    user_id: string;
  } | null>(null);

  const edit = (
    value: boolean,
    review: { item_id: string; user_id: string } | null
  ) => {
    setEditReview(value);
    setEditedReview(review);
  };

  return (
    <>
      {editReview ? (
        <EditReview editReview={edit} data={editedReview} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Отзывы</h1>
            </Group>
          </div>
          <ReviewTable editReview={edit} openedSideBar={openedSideBar} />
        </>
      )}
    </>
  );
}

export default Reviews;
