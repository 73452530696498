import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  TextInput,
} from "@mantine/core";
import { Search } from "tabler-icons-react";
import { getAvailableProducts, getProducts } from "../../lib/api/products";
import {
  AvailableProductCollectionResponse,
  ProductCollection,
  ProductCollectionResponse,
} from "../../lib/api/types/products";
import Paginator from "../../shared/paginator";
import { QuantitySelector } from "../../shared/quantitySelector";
import { formatRuble } from "../../lib/utils";

type Props = {
  headers: string[];
  productsList: {
    id: string;
    quantity: number;
  }[] | null;
  type: string;
  handleProduct: (
    productId: string,
    type: string,
    price: number,
    quantity: number
  ) => void;
  setTotalPrice: (price: number) => void;
  totalPrice: number;
  setProductList: (
    list: {
      id: string;
      quantity: number;
    }[] | null
  ) => void;
};

const ProductsTable: React.FC<Props> = ({
  headers,
  productsList,
  type,
  handleProduct,
  setTotalPrice,
  totalPrice,
  setProductList,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const [data, setData] = useState<ProductCollection[]>();
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);

  const fetchData = async () => {
    if (type === "selected") {
      const offset = (activePage - 1) * selectedRowsNumber;
      const products: ProductCollectionResponse = await getProducts({
        product_ids: productsList ? productsList.map((product) => product.id) : null,
        offset: offset,
        limit: selectedRowsNumber,
      });
      setData(products.products);
      setTotal(products.total);
    } else if (type === "select") {
      const offset = (activePage - 1) * selectedRowsNumber;
      const products: AvailableProductCollectionResponse =
        await getAvailableProducts({
          search: searchValue,
          excluded_product_ids: productsList ? productsList.map((product) => product.id) : null,
          offset: offset,
          limit: selectedRowsNumber,
        });
      setData(products.products);
      setTotal(products.total);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, activePage, selectedRows, productsList]);

  const handleResetFilters = () => {
    setSearchValue("");
    setPage(1);
  };

  const rows = data?.map((item, index) => {
    const productInList = productsList?.find((product) => product.id === item.item_id);
    const quantity = productInList ? productInList.quantity : 1;
    const totalProductPrice = item.price ? item.price * quantity : 0;

    return (
      <Table.Tr key={index}>
        <Table.Td>{item.code}</Table.Td>

        <Table.Td>{item.name}</Table.Td>
        <Table.Td>{formatRuble(item.price as number)}</Table.Td>

        {type === "selected" && (
          <Table.Td>
            <QuantitySelector
              maxValue={item.total_quantity ? item.total_quantity : 1}
              productId={item.item_id}
              setProductList={setProductList}
              productList={productsList}
              totalPrice={totalPrice}
              itemPrice={item.price as number}
              value={quantity}
              setTotalPrice={setTotalPrice}
            />
          </Table.Td>
        )}
        {type === "selected" && (
          <Table.Td>{formatRuble(totalProductPrice)}</Table.Td>
        )}


        <Table.Td>
          {type === "select" ? (
            <Button
              size="md"
              radius="sm"
              style={{
                backgroundColor: "#0560BE",
                color: "#ffffff",
                border: "1px solid #0560BE",
                borderRadius: 12,
                fontSize: 15,
                textAlign: "center",
                width: "120px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleProduct(item.item_id, "select", item.price as number, quantity)
              }
            >
              Добавить
            </Button>
          ) : (
            <Button
              size="md"
              radius="sm"
              style={{
                flex: 0.3,
                backgroundColor: "white",
                color: "#25262B",
                border: "1px solid #0560BE",
                borderRadius: 12,
                fontSize: 15,
                width: "120px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleProduct(item.item_id, "selected", item.price as number, quantity)
              }
            >
              Удалить
            </Button>
          )}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div className="w-full">
      {type === "select" && (
        <div style={{ display: "flex", marginBottom: "1rem", gap: "1rem" }}>
          <TextInput
            style={{ flex: 1 }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            placeholder="Наименование товара или артикул"
            leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
            size="md"
            radius="md"
          />
          <Button
            onClick={handleResetFilters}
            size="md"
            radius="sm"
            style={{
              backgroundColor: "white",
              color: "#25262B",
              borderRadius: 12,
              fontSize: 15,
              width: "120px",
            }}
          >
            Сбросить
          </Button>
        </div>
      )}

      <>
        <Table withTableBorder verticalSpacing="md">
          <Table.Thead
            style={{ color: "#1E2134", backgroundColor: "#FAFAFA" }}
          >
            <Table.Tr>
              {headers.map((header, index) => (
                <Table.Th key={index}>{header}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>

        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </>
    </div>
  );
};

export default ProductsTable;
