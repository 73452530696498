import { useState } from "react";
import { Button, Group } from "@mantine/core";
import "./main-pages.css";
import BannerTable from "../components/banners/banner-table";
import { useDisclosure } from "@mantine/hooks";
import "@mantine/dropzone/styles.css";
import BannerModal from "../components/banners/bannerModal";

function Banner({ openedSideBar }: { openedSideBar: boolean }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalTitle, setModalTitle] = useState("Создание баннера");
  const [updateData, setUpdateData] = useState<boolean>(false);

  const [ID, setID] = useState<string | null>(null);

  const handleCreateBannerClick = () => {
    setModalTitle("Создание баннера");
    setID(null);
    open();
  };

  return (
    <>
      <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
        <Group style={{ paddingLeft: 0 }}>
          <h1 className="basefont-h1">Баннеры</h1>
        </Group>
        <Group>
          <Button
            size="md"
            radius="md"
            style={{
              backgroundColor: "#0560BE",
              borderRadius: 12,
              fontSize: 15,
              marginBottom: 10,
            }}
            onClick={handleCreateBannerClick}
          >
            + Создать баннер
          </Button>
        </Group>
      </div>
      {opened && (
        <BannerModal
          opened={opened}
          close={close}
          modalTitle={modalTitle}
          id={ID}
          setUpdateData={setUpdateData}
        />
      )}

      <BannerTable
        setID={setID}
        openModal={open}
        setModalTitle={setModalTitle}
        updateData={updateData}
        openedSideBar={openedSideBar}
      />
    </>
  );
}

export default Banner;
