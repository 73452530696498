import { AppShell, Burger, Group, MantineProvider, Menu, Loader } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "./shell.css";
import { useEffect, useState } from "react";
import {
  ShoppingCart,
  UserCircle,
  ShoppingCartOff,
  AlertCircle,
  MessageCircle,
  MapPin,
  User,
  FileOrientation,
  Hammer,
} from "tabler-icons-react";
import Orders from "./pages/order";
import Banner from "./pages/banner";
import Reviews from "./pages/reviews";
import Stores from "./pages/stores";
import Employees from "./pages/employees";
import ErrorProducts from "./pages/errorProducts";
import AbandonedCarts from "./pages/abandonedCarts";
import Articles from "./pages/articles";
import PromotionsAndNews from "./pages/PromotionsAndNews";
import EDO from "./pages/edo";
import Collection from "./pages/collection";
import ChangePasswordModal from "./components/modals/changePasswordModal";
import ConfirmExitModal from "./components/modals/confirmSignOutModal";
import { Admin } from "./lib/api/types/admin";
import { getAdmin } from "./lib/api/admin";
import { useNavigate } from "react-router-dom";
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';

export function Shell() {
  const [opened, { toggle }] = useDisclosure();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [
    changePasswordOpened,
    { open: openChangePassword, close: closeChangePassword },
  ] = useDisclosure(false);
  const [
    confirmExitOpened,
    { open: openConfirmExit, close: closeConfirmExit },
  ] = useDisclosure(false);

  const BannerIcon = `${process.env.PUBLIC_URL}/icons/banner.svg`;
  const ArticlesIcon = `${process.env.PUBLIC_URL}/icons/article.svg`;
  const PercentageIcon = `${process.env.PUBLIC_URL}/icons/sale.svg`;
  const [admin, setAdmin] = useState<Admin>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const adminData = await getAdmin();
        setAdmin(adminData);
      } catch (error) {
        console.error("Failed to fetch admin:", error);
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allOptions = [
    { path: "/orders", label: "Заказы", icon: ShoppingCart, component: Orders },
    { path: "/promotions-and-news", label: "Акции и новости", icon: PercentageIcon, component: PromotionsAndNews },
    { path: "/articles", label: "Статьи", icon: ArticlesIcon, component: Articles },
    { path: "/banner", label: "Баннеры", icon: BannerIcon, component: Banner },
    { path: "/collection", label: "Подборки товаров", icon: Hammer, component: Collection },
    { path: "/abandoned-carts", label: "Брошенные корзины", icon: ShoppingCartOff, component: AbandonedCarts },
    { path: "/error-products", label: "Товары с ошибкой", icon: AlertCircle, component: ErrorProducts },
    { path: "/reviews", label: "Отзывы", icon: MessageCircle, component: Reviews },
    { path: "/stores", label: "Магазины", icon: MapPin, component: Stores },
    { path: "/edo", label: "ЭДО", icon: FileOrientation, component: EDO },
    { path: "/employees", label: "Сотрудники", icon: User, component: Employees },
  ];

  const getRoleBasedOptions = () => {
    if (admin?.role === "Aдминистратор") {
      return allOptions;
    }
    if (admin?.role === "Mенеджер ИМ") {
      return allOptions.filter((item) =>
        ["Заказы", "Брошенные корзины", "Товары с ошибкой", "Магазины", "ЭДО"].includes(item.label)
      );
    }
    if (admin?.role === "Kонтент-менеджер") {
      return allOptions.filter((item) =>
        ["Акции и новости", "Статьи", "Баннеры", "Подборки товаров", "Отзывы"].includes(item.label)
      );
    }
    return [];
  };
  const options = getRoleBasedOptions();

  const links = options.map((item) => (
    <NavLink
      className={({ isActive }) =>
        `options mx-auto ${isActive ? 'active' : ''}`
      }
      to={item.path}
      key={item.label}
    >
      {typeof item.icon === "string" ? (
        <img
          src={item.icon}
          className="optionsIcon"
          style={{ color: "white" }}
          alt={item.label}
          width={25}
          height={25}
        />
      ) : (
        <item.icon className="optionsIcon" size={25} strokeWidth={2} />
      )}
      {opened && <span className="label">{item.label}</span>}
    </NavLink>
  ));

  const defaultPath = options.length > 0 ? options[0].path : '/';

  return (
    <MantineProvider>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: opened ? 210 : 50,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <>
          <AppShell.Header>
            <div className="header-content">
              <Group px="md">
                <Burger opened={opened} onClick={toggle} size="md" />
                <img src="/logo.svg" alt="Logo" />
              </Group>
              <Group px="md">
                <Menu
                  opened={dropdownOpened}
                  onClose={() => setDropdownOpened(false)}
                  onOpen={() => setDropdownOpened(true)}
                >
                  <Menu.Target>
                    <Group>
                      <span className="user-info">
                        <span
                          style={{
                            color: "#506176",
                            fontWeight: "bold",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                        >
                          {admin?.first_name} {admin?.father_name} {admin?.last_name}
                        </span>
                        <span
                          style={{
                            color: "#506176",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {admin?.role}
                        </span>
                      </span>
                      <UserCircle size={40} strokeWidth={1} color={"#506176"} cursor={"pointer"} />
                    </Group>
                  </Menu.Target>
                  <Menu.Dropdown style={{ width: "200px", borderRadius: "12px" }}>
                    <Menu.Item onClick={openConfirmExit}>Выход</Menu.Item>
                    <Menu.Item onClick={openChangePassword}>Изменить пароль</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </div>
          </AppShell.Header>
          <AppShell.Navbar style={{ padding: 0 }}>
            <div>{links}</div>
          </AppShell.Navbar>
          <AppShell.Main>
            {loading ? (
              <div className="h-screen flex items-center justify-center">
                <Loader size="sm" />
              </div>
            ) : (
              <Routes>
                {options.map((item) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={<item.component openedSideBar={opened} />}
                  />
                ))}
                <Route path="*" element={<Navigate to={defaultPath} replace />} />
              </Routes>
            )}
          </AppShell.Main>
        </>
      </AppShell>

      <ChangePasswordModal
        opened={changePasswordOpened}
        close={closeChangePassword}
      />
      <ConfirmExitModal opened={confirmExitOpened} close={closeConfirmExit} />
    </MantineProvider>
  );
}

export default Shell;
