import { Group } from "@mantine/core";
import "./main-pages.css";

import ErrorProductsTable from "../components/errorProductsTable";

const ErrorProducts = ({ openedSideBar }: { openedSideBar: boolean }) => {
  return (
    <>
      <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
        <Group style={{ paddingLeft: 0 }}>
          <h1 className="basefont-h1">Товары с ошибкой</h1>
        </Group>
      </div>
      <ErrorProductsTable openedSideBar={openedSideBar} />
    </>
  );
};

export default ErrorProducts;
