import { Loader, Modal, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "../../shared/button";
import { useEffect, useState } from "react";
import { createAdmin, getAdminById, updateAdmin } from "../../lib/api/employee";
import { useToast } from "@chakra-ui/react";

interface EmployeeFormValues {
  first_name: string;
  last_name: string;
  father_name: string;
  email: string;
  password: string;
  role: string;
  active: boolean;
  created: string;
}

interface EmployeeModalProps {
  opened: boolean;
  close: () => void;
  modalTitle: string;
  id: string | null;
  setUpdateData: (any: any) => void;
}

const roleMap: Record<string, string> = {
  super_admin: 'Администратор',
  manager: 'Менеджер ИМ',
  content_manager: 'Контент-менеджер',
};

const EmployeeModal: React.FC<EmployeeModalProps> = ({
  opened,
  close,
  modalTitle,
  id,
  setUpdateData
}) => {
  const fieldStyles = {};
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<EmployeeFormValues>({
    initialValues: {
      first_name: "",
      created: "",
      last_name: "",
      father_name: "",
      email: "",
      password: "",
      role: "",
      active: false,
    },
    validate: {
      first_name: (value) => (value ? null : 'Имя обязательно'),
      last_name: (value) => (value ? null : 'Фамилия обязательна'),
      father_name: (value) => (value ? null : 'Отчество обязательно'),
      email: (value) => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        return isValidEmail ? null : 'Неверный формат email';
      },
      password: (value) => (value ? null : 'Пароль обязателен'),
      role: (value) => (value ? null : 'Роль обязательна'),
      active: (value) => (typeof value === "boolean" ? null : 'Статус активности обязателен'),
    },
  });

  const [loading, setLoading] = useState(true);

  const toast = useToast();

  useEffect(() => {
    const fetchAdmin = async () => {
      if (id) {
        setLoading(true);
        try {
          const admin = await getAdminById(id);
          form.setValues({
            first_name: admin.first_name,
            role: admin.role,
            last_name: admin.last_name,
            active: admin.active,
            created: admin.created,
            email: admin.email,
            father_name: admin.father_name
          });
        } catch (error) {
          console.error("Не удалось получить данные сотрудника:", error);
          toast({
            title: "Ошибка",
            description: "Ошибка сервера.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      } else {
        form.reset();
        setLoading(false);
      }
    };
    fetchAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не Активен";

  const handleAdmin = async (values: EmployeeFormValues) => {
    setIsSubmitting(true);
    try {
      if (id) {
        const updatedValues = {
          ...values,
          password: values.password.trim() === "" ? null : values.password,
        };
        await updateAdmin(id, updatedValues);
        toast({
          title: "Успех",
          description: "Сотрудник успешно обновлен.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await createAdmin(values);
        toast({
          title: "Успех",
          description: "Сотрудник успешно создан.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setUpdateData((prev: any) => !prev);
      form.reset();
      close();
    } catch (error) {
      console.error("Не удалось сохранить данные сотрудника:", error);
      toast({
        title: "Ошибка",
        description: "Ошибка сервера.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  }

  const handleSubmit = async () => {
    await handleAdmin(form.values);
  };

  useEffect(() => {
    if (!opened) {
      setIsSubmitting(false);
      form.reset();
    }
  }, [opened, form]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={modalTitle}
      centered
      className="personal-model"
    >
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Имя"
            placeholder="Введите имя"
            style={{ padding: "12px" }}
            value={form.values.first_name}
            {...form.getInputProps("first_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Фамилия"
            placeholder="Введите фамилию"
            style={{ padding: "12px" }}
            value={form.values.last_name}
            {...form.getInputProps("last_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Отчество"
            placeholder="Введите отчество"
            style={{ padding: "12px" }}
            value={form.values.father_name}
            {...form.getInputProps("father_name")}
            styles={fieldStyles}
          />
          <TextInput
            label="Почта"
            placeholder="Введите почту"
            style={{ padding: "12px" }}
            {...form.getInputProps("email")}
            styles={fieldStyles}
          />

          <TextInput
            label={
              modalTitle === "Добавить сотрудника" ? "Пароль" : "Новый пароль"
            }
            placeholder={
              modalTitle === "Добавить сотрудника"
                ? "Введите пароль"
                : "Введите новый пароль"
            }
            style={{ padding: "12px" }}
            {...form.getInputProps("password")}
            value={form.values.password}
            styles={fieldStyles}
          />
          <Select
            label="Должность"
            placeholder="Выберите должность"
            size="md"
            radius="md"
            style={{ padding: "12px" }}
            value={roleMap[form.values.role]}
            data={["Администратор", "Менеджер ИМ", "Контент-менеджер"]}
            onChange={(value) => {
              const selectedType = Object.keys(roleMap).find((key) => roleMap[key] === value);
              form.setFieldValue("role", selectedType!);
            }}
          />

          {modalTitle !== "Добавить сотрудника" && (
            <Select
              label="Статус активности"
              placeholder="Выберите статус"
              data={["Активен", "Не Активен"]}
              style={{ padding: "12px" }}
              {...form.getInputProps("active")}
              value={getStatusLabel(form.values.active)}
              onChange={(value) =>
                form.setFieldValue("active", value === "Активен")
              }
              size="md"
              radius="md"

            />
          )}

          <div
            className="flex flex-col gap-2 mt-2"
            style={{
              justifyContent:
                modalTitle === "Добавить сотрудника" ? "center" : "space-between",
            }}
          >
            <Button
              type="submit"
              style={{
                backgroundColor: "#0560BE",
                width: "410px",
                height: "48px",
                padding: "8px, 20px, 8px, 20px",
                marginLeft: "10px",
              }}disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader size="sm" color="white" /> {/* Индикатор загрузки */}
                    <span style={{ marginLeft: 8 }}>Сохраняется...</span>
                  </>
                ) : (
                  modalTitle === "Добавить сотрудника" ? "Добавить" : "Сохранить"
                )}
            </Button>
            {modalTitle !== "Добавить сотрудника" && (
              <Button
                variant="outline"
                onClick={close}
                style={{
                  width: "410px",
                  height: "48px",
                  padding: "8px, 20px, 8px, 20px",
                  marginLeft: "10px",
                }} disabled={isSubmitting}
              >
                Отменить
              </Button>
            )}
          </div>
        </form>
      )}
    </Modal>
  );
};

export default EmployeeModal;
