import { useState } from "react";
import { Button, Group } from "@mantine/core";

import "./main-pages.css";
import StoreForm from "../components/stores/store-form";
import StoreTable from "../components/stores/store-table";
import { Store } from "../lib/api/types/store";

const Stores = ({ openedSideBar }: { openedSideBar: boolean }) => {
  const [editMode, setEditMode] = useState(false);
  const [currentStore, setCurrentStore] = useState<Store | null>(null);

  const handleCreateStoreClick = () => {
    setEditMode(true);
    setCurrentStore(null);
  };

  const handleEditStore = (store: Store) => {
    setEditMode(true);
    setCurrentStore(store);
  };

  const closeForm = () => {
    setEditMode(false);
    setCurrentStore(null);
  };

  return (
    <>
      {editMode ? (
        <StoreForm store={currentStore} closeModal={closeForm} />
      ) : (
        <>
          <div className="header-content-main-pages" style={{ paddingLeft: 0 }}>
            <Group style={{ paddingLeft: 0 }}>
              <h1 className="basefont-h1">Магазины</h1>
            </Group>
            <Group>
              <Button
                size="md"
                radius="md"
                style={{
                  backgroundColor: "#0560BE",
                  borderRadius: 12,
                  fontSize: 15,
                  marginBottom: 10,
                }}
                onClick={handleCreateStoreClick}
              >
                + Добавить магазин
              </Button>
            </Group>
          </div>
          <StoreTable
            onEditStore={handleEditStore}
            openedSideBar={openedSideBar}
          />
        </>
      )}
    </>
  );
};

export default Stores;
