import axios from "./base";
import { CartResponse, Cart, ProductsResponse } from "./types/carts";

export const fetchCarts = async (params: {
  search: string;
  modified_from: string | null;
  modified_to: string | null;
  offset: number;
  limit: number;
}): Promise<CartResponse> => {
  const response = await axios.post("/carts/query", params);
  return response.data;
};

export const fetchCartDetails = async (
  cart_id: string
): Promise<{ cart: Cart }> => {
  const response = await axios.get(`/carts/${cart_id}`);
  return response.data;
};

export const fetchCartProducts = async (
  cart_id: string,
  param: { offset: number; limit: number }
): Promise<ProductsResponse> => {
  const response = await axios.post(`/carts/${cart_id}/products/query`, param);
  return response.data;
};
