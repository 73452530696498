import axios from "./base";
import { GetReviewsParams, Review, ReviewDetail } from "./types/review";

export const getReviews = async (
  params: GetReviewsParams
): Promise<{ reviews: Review[]; total: number }> => {
  const { data } = await axios.post("/reviews/query", params);
  return data;
};

export const getReview = async (
  item_id: string,
  user_id: string
): Promise<ReviewDetail> => {
  const response = await axios.get(`/reviews/${item_id}/${user_id}`);
  return response.data.review;
};

export const updateReview = async (
  item_id: string,
  user_id: string,
  status: string
): Promise<void> => {
  await axios.put(`/reviews/${item_id}/${user_id}`, { status });
};
