import React, { useState, useEffect } from "react";
import { Button, TextInput } from "@mantine/core";
import { Search } from "tabler-icons-react";
import { formatRuble } from "../../lib/utils";
import { PickUpPoint } from "../../lib/api/types/orders";

interface PickupPointComponentProps {
  pickUpPoint: PickUpPoint | null;
  pickUpPoints: Array<PickUpPoint> | undefined;
  setSelectedPickupPoint: React.Dispatch<React.SetStateAction<any>>;
  price_delivery: number;
}

const PickupPointComponent: React.FC<PickupPointComponentProps> = ({
  price_delivery,
  pickUpPoints,
  pickUpPoint,
  setSelectedPickupPoint,
}) => {
  const [address, setAddress] = useState(pickUpPoint?.address || "");
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(pickUpPoints?.length || 0);
  const [fetching, setFetching] = useState(false);
  const [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    if (pickUpPoints) {
      setTotal(pickUpPoints.length);
    }
  }, [pickUpPoints]);

  const handleSelectPickupPoint = (pickupPoint: PickUpPoint) => {
    setSelectedPickupPoint(pickupPoint);
    setAddress(pickupPoint.address);
    setListVisible(false);
  };

  const handleResetFilters = () => {
    setAddress("");
    setListVisible(true);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;

    if (bottom && limit < total && !fetching) {
      setFetching(true);
      setTimeout(() => {
        setLimit((prevLimit) => prevLimit + 10);
        setFetching(false);
      }, 500);
    }
  };

  const filteredPoints = pickUpPoints?.filter((point) =>
    point.address.toLowerCase().includes(address.toLowerCase())
  );

  const displayedPoints = filteredPoints?.slice(0, limit);

  return (
    <div>
      <h2 className="basefont-h2 mb-8 mt-4">Выбор пункта выдачи</h2>
      <div style={{ display: "flex" }}>
        <TextInput
          style={{ flex: 1, width: "1318px" }}
          value={address}
          onFocus={() => setListVisible(true)}
          onChange={(e) => {
            setAddress(e.currentTarget.value);
            setListVisible(true);
          }}
          placeholder="Введите адрес пункта выдачи"
          leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
          size="md"
          radius="md"
        />
        <Button
          onClick={handleResetFilters}
          size="md"
          radius="sm"
          style={{
            backgroundColor: "white",
            color: "#25262B",
            borderRadius: 12,
            fontSize: 15,
            width: "120px",
          }}
        >
          Сбросить
        </Button>
      </div>

      {listVisible && (
        <div
          onScroll={handleScroll}
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            marginTop: "16px",
            border: "1px solid #ccc",
            padding: "8px",
          }}
        >
          {displayedPoints?.map((point) => (
            <div
              key={point.id}
              onClick={() => handleSelectPickupPoint(point)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderRadius: "8px",
                paddingBottom: "12px",
                backgroundColor:
                  pickUpPoint?.id === point.id ? "#f0f0f0" : "transparent",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#e6f7ff")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#f9f9f9")
              }
            >
              <strong>{point.address}</strong>
            </div>
          ))}

          {fetching && (
            <div
              style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}
            >
              <div>Loading...</div>
            </div>
          )}
        </div>
      )}

      <div className="bg-[#99BEE4] w-full rounded-xl p-[8px] text-txt-primary mt-[24px]">
        Стоимость доставки в пункт выдачи: {formatRuble(price_delivery)}
      </div>
    </div>
  );
};

export default PickupPointComponent;
