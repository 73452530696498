
import apiClient from './base';
import {
  EmployeeQueryParams,
  EmployeeResponse,
  CreateEmployeeRequest,
  UpdateEmployeeRequest,
  GetEmployeeResponse,
  Employee,
} from './types/employee';

export const queryAdmins = async (params: EmployeeQueryParams): Promise<EmployeeResponse> => {
  const response = await apiClient.post<EmployeeResponse>('/admins/query', params);
  return response.data;
};

export const createAdmin = async (data: CreateEmployeeRequest): Promise<void> => {
  await apiClient.post('/admins', data);
};

export const updateAdmin = async (id: string, data: UpdateEmployeeRequest): Promise<void> => {
  await apiClient.put(`/admins/${id}`, data);
};

export const getAdminById = async (id: string): Promise<Employee> => {
  const response = await apiClient.get<GetEmployeeResponse>(`/admins/${id}`);
  return response.data.admin;
};
