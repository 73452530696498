import axios from "./base";
import { BonusesResponse, CreateOrderParams, EditOrderParams, GetOrderByIdResponse, OrderResponse, PickupPointsResponse, PreOrderBonusesParams, PreOrderParams, StoresResponse } from "./types/orders";

export const fetchOrders = async (params: {
  search: string;
  statuses: string[] | null,
  payment_methods: string[] | null,
  receive_types: string[] | null,
  date_from: string | null,
  date_to: string | null,
  offset: number;
  limit: number;
}): Promise<OrderResponse> => {
  const response = await axios.post("/orders/query", params);
  return response.data;
};

export const getOrderById = async (orderNumber: number): Promise<GetOrderByIdResponse> => {
  const response = await axios.get(`/orders/${orderNumber}`);
  return response.data;
}

export const preOrderDates = async ({ user_id, items }: PreOrderParams): Promise<StoresResponse> => {
  const response = await axios.post("/orders/pre-order", {
    params: {
      user_id,
      items,
    },
  });
  return response.data;
};

export const preOrderBonuses = async (params: PreOrderBonusesParams): Promise<BonusesResponse> => {
  const response = await axios.post("/orders/pre-order/bonuses", params);
  return response.data;
}
export const preOrderPickUpPoints = async (params: PreOrderParams): Promise<PickupPointsResponse> => {
  const response = await axios.post("/orders/pre-order/pickup-points", params);
  return response.data;
}

export const createOrder = async (params: CreateOrderParams): Promise<GetOrderByIdResponse> => {
  const response = await axios.post('/orders/create', params);
  return response.data;
};

export const editOrder = async (orderNumber: number, params: EditOrderParams): Promise<GetOrderByIdResponse> => {
  const response = await axios.put(`/orders/${orderNumber}`, params);
  return response.data;
};