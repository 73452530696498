import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://new.999111.ru/admin/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshAccessToken = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (refresh_token) {
    try {
      const response = await apiClient.post("/admins/auth/refresh-tokens", {
        refresh_token,
      });

      const { access_token } = response.data;
      localStorage.setItem("access_token", access_token);
      return access_token;
    }
    catch {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
  }

  return null;
};

apiClient.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
