import React, { useState, useEffect } from "react";
import { TextInput, Checkbox, Button } from "@mantine/core";
import { DeliveryDetails } from "../../lib/api/types/orders";

const DeliveryComponent: React.FC<{
  deliveryDetails: DeliveryDetails | null;
  setDeliveryDetails: React.Dispatch<React.SetStateAction<any>>;
}> = ({ deliveryDetails, setDeliveryDetails }) => {

  const [entrance, setEntrance] = useState<string>(deliveryDetails?.entrance || "");
  const [comment, setComment] = useState<string>(deliveryDetails?.comment || "");
  const [lift, setLift] = useState<boolean>(deliveryDetails?.lift || false);
  const [floor, setFloor] = useState<number | ''>(deliveryDetails?.floor || "");
  const [flat, setFlat] = useState<number | ''>(deliveryDetails?.flat || "");
  const [date, setDate] = useState<string>(deliveryDetails?.date || new Date().toISOString());
  const [address, setAddrees] = useState<string>(deliveryDetails?.address || "");
  useEffect(() => {
    if (deliveryDetails) {
      setAddrees(deliveryDetails.address)
      setEntrance(deliveryDetails.entrance);
      setComment(deliveryDetails.comment);
      setLift(deliveryDetails.lift);
      setFloor(deliveryDetails.floor);
      setFlat(deliveryDetails.flat);
      setDate(deliveryDetails.date);
    } else {
      setAddrees("")
      setEntrance("");
      setComment("");
      setLift(false);
      setFloor("");
      setFlat("");
      setDate(new Date().toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDetails]);

  useEffect(() => {
    setDeliveryDetails({
      address,
      entrance,
      comment,
      lift,
      floor: typeof floor === 'number' ? floor : 0,
      flat: typeof flat === 'number' ? flat : 0,
      date,
    });
  }, [address, entrance, comment, lift, floor, flat, date, setDeliveryDetails]);

  const handleResetFilters = () => {
    setAddrees("")
    setEntrance("");
    setComment("");
    setLift(false);
    setFloor("");
    setFlat("");
    setDate(new Date().toISOString());
  };

  return (
    <div className="gap-4 flex flex-col">
      <h2 className="basefont-h2 mt-4 mb-4">Адрес доставки</h2>
      <div style={{ display: "flex" }}>
        <TextInput
          style={{ flex: 1, width: "1318px", backgroundColor: "white" }}
          value={address}
          onChange={(e) => setAddrees(e.currentTarget.value)}
          placeholder="Поиск по названию магазина"
          size="md"
          radius="md"
        />
        <Button
          onClick={handleResetFilters}
          size="md"
          radius="sm"
          style={{
            backgroundColor: "white",
            color: "#25262B",
            borderRadius: 12,
            fontSize: 15,
            width: "120px",
          }}
        >
          Сбросить
        </Button>
      </div>
      <div style={{ display: "flex", gap: "24px", marginRight: "10px" }}>
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер подъезда"
          size="md"
          label="Подъезд"
          radius="md"
          value={entrance}
          onChange={(e) => setEntrance(e.currentTarget.value)}
        />
        <TextInput
          style={{ width: "100%" }}
          placeholder="Оставьте комментарий курьеру"
          size="md"
          label="Комментарий курьеру"
          radius="md"
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
        />
      </div>

      {/* <h2 className="basefont-h2 mt-4 mb-4">Варианты доставки</h2>
      <Group>
        <Radio.Group>
          <Group
            mt="xs"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Radio 
              variant="outline" 
              value="entrance" 
              label="До подъезда (без подъема)" 
              onChange={() => setLift(false)} 
            />
            <Radio 
              variant="outline" 
              value="floor" 
              label="С подъемом на этаж" 
              onChange={() => setLift(true)} 
            />
          </Group>
        </Radio.Group>
      </Group> */}

      <div style={{ display: "flex", gap: "24px", marginRight: "10px" }}>
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер этажа"
          size="md"
          label="Этаж"
          radius="md"
          value={floor === '' ? '' : floor}
          onChange={(e) => setFloor(Number(e.currentTarget.value))}
        />
        <TextInput
          style={{ width: "100%" }}
          placeholder="Введите номер квартиры"
          size="md"
          label="Квартира"
          radius="md"
          value={flat === '' ? '' : flat}
          onChange={(e) => setFlat(Number(e.currentTarget.value))}
        />
      </div>
      <Checkbox
        label="Есть лифт"
        checked={lift}
        onChange={(e) => setLift(e.currentTarget.checked)}
      />
    </div>
  );
};

export default DeliveryComponent;
