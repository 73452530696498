import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../lib/utils';



const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-[10px] basefont-b1 gap-3 ring-offset-white transition-colors select-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        default: 'bg-default-blue text-txt-inverted hover:darken',
        outline: 'border border-stroke bg-white dark:border-slate-800 hover:darken',
        secondary: 'bg-slate-100 text-slate-900 hover:bg-slate-100/80',
        ghost: 'basefont-b2 text-txt-secondary flex',
        'ghost-blue': 'basefont-b2 text-default-blue flex',
        'ghost-red': 'basefont-b2 text-default-red flex',
        link: 'text-slate-900 underline-offset-4 hover:underline dark:text-slate-50',
        action: 'bg-bg-light-gray',
      },
      size: {
        default: 'h-[44px] px-6 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-9 w-9 rounded-full',
      },
    },
    compoundVariants: [
      {
        variant: ['ghost', 'ghost-blue', 'ghost-red'],
        size: 'default',
        className: 'p-0',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disableContent?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, disableContent = false, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disableContent}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
