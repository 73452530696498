import { useForm } from "@mantine/form";
import {
  Button as MantineButton,
  Group,
  TextInput,
  Select,
  Loader,
} from "@mantine/core";
import { ChevronLeft } from "tabler-icons-react";
import { Button } from "../../shared/button";
import {
  CollectionFormValues,
  UpdateCollection,
} from "../../lib/api/types/collection";
import {
  addCollection,
  getCollectionById,
  updateCollection,
} from "../../lib/api/collections";
import { useEffect, useState } from "react";
import ProductsTable from "./productsTable";
import { useToast } from "@chakra-ui/react";
import { updateProductMeta } from "../../lib/api/products";

interface CollectionFormProps {
  title: string;
  id: string | null;
  close: () => void;
}

const tagOptions = [
  { value: "promo", label: "Акция" },
  { value: "sale", label: "Распродажа" },
  { value: "new", label: "Новинка" },
  { value: "online_only", label: "Только онлайн" },
  { value: "more_bonuses", label: "Больше бонусов" },
];

const CollectionForm: React.FC<CollectionFormProps> = ({
  title,
  close,
  id,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const form = useForm<CollectionFormValues>({
    initialValues: {
      id: "",
      name: "",
      active: true,
      product_ids: [""],
      tag: "",
    },
    validate: {
      name: (value) => (value ? null : "Имя коллекции обязательно"),
      active: (value) => (value ? null : "Статус активности обязателен"),
    },
  });

  const [collectionProduct, setCollectionProduct] = useState<string[] | null>(null);

  useEffect(() => {
    const fetchCollection = async () => {
      if (id) {
        setLoading(true);
        try {
          const collection = await getCollectionById(id);
          setCollectionProduct(
            collection.product_ids ? collection.product_ids : null
          );
          form.setValues({
            id: collection.id,
            name: collection.name,
            active: collection.active,
            tag: collection.tag
          });
        } catch (error) {
          console.error("Failed to fetch collection:", error);
          toast({
            title: "Ошибка",
            description: "Ошибка сервера.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      } else {
        form.reset();
        setLoading(false);
      }
    };
    fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.reset();
    close();
  };

  const handleCollection = async (values: CollectionFormValues) => {
    setIsSubmitting(true);
    const formattedValues: UpdateCollection = {
      active: values.active,
      name: values.name,
      product_ids: collectionProduct ? collectionProduct : null,
      tag: values.tag,
    };
    try {
      if (values.id) {
        await updateCollection(values.id, formattedValues);
        toast({
          title: "Успех",
          description: "Коллекция успешно обновлена.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await addCollection(formattedValues);
        toast({
          title: "Успех",
          description: "Коллекция успешно добавлена.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setTimeout(() => {
        form.reset();
        close();
      }, 1300);
    } catch (error) {
      console.error("Failed to save collection:", error);
      toast({
        title: "Ошибка",
        description: "Ошибка сервера.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    } 
  };

  const handleProduct = (productId: string, type: string) => {
    if (type === "select") {
      setCollectionProduct((prevProducts) => {
        const products = prevProducts || [];
        return [...products, productId];
      });
    } else if (type === "selected") {
      setCollectionProduct((prevProducts) => {
        const products = prevProducts || [];
        return products.filter((id) => id !== productId);
      });
    }
  };

  const headers = [
    "ID товара на сайте",
    "Наименование товара",
    "Действие",
  ];

  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не активен";

  const inputStyles = {
    input: {
      cursor: 'default',
      color: '#25262B',
      backgroundColor: '#f1f3f5',
    },
  };

  return (
    <div>
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <MantineButton
            leftSection={<ChevronLeft size={20} />}
            onClick={handleCancel}
            style={{
              backgroundColor: "#ffffff",
              color: "#25262B",
              fontSize: "16px",
              padding: 0,
              border: "#ffffff",
              transition: "background-color 0.3s",
              marginBottom: "2%",
            }}
          >
            Назад
          </MantineButton>
          <div className="flex flex-col gap-6">
            <Group
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 className="basefont-h1">{title}</h1>
              <Group>
                {title === "Редактирование подборки" && (
                  <Button
                    type="button"
                    onClick={handleCancel}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#1E2134",
                      width: "200px",
                    }}
                  >
                    Отменить
                  </Button>
                )}

                <Button
                  type="button"
                  onClick={() => form.onSubmit(handleCollection)()}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "200px",
                  }}disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Loader size="sm" color="white" /> {/* Индикатор загрузки */}
                        <span style={{ marginLeft: 8 }}>Сохраняется...</span>
                      </>
                    ) : (
                      "Сохранить"
                    )}
                </Button>
              </Group>
            </Group>
            {title === "Создание подборки" ? (
              <>
                <Group>
                  <TextInput
                    style={{ flex: 0.5 }}
                    label="Название"
                    placeholder="Введите название"
                    {...form.getInputProps("name")}
                    size="md"
                    radius="md"
                  />
                  <Select
                    style={{ flex: 0.5 }}
                    data={tagOptions}
                    label="Лейбл"
                    value={form.values.tag}
                    onChange={(value) => form.setFieldValue("tag", value || "")}
                    placeholder="Выберите Лейбл"
                    size="md"
                    radius="md"
                  />
                </Group>
                <Group>
                  <Select
                    style={{ flex: 0.5 }}
                    label="Статус активности"
                    placeholder="Выберите статус"
                    data={["Активен", "Не активен"]}
                    value={getStatusLabel(form.values.active)}
                    onChange={(value) =>
                      form.setFieldValue("active", value === "Активен")
                    }
                    size="md"
                    radius="md"
                  />
                </Group>
              </>
            ) : (
              <>
                <Group>
                  <TextInput
                    style={{ flex: 0.5 }}
                    label="Название"
                    placeholder="Введите название"
                    {...form.getInputProps("name")}
                    size="md"
                    radius="md"
                  />
                  <Select
                    style={{ flex: 0.5 }}
                    data={tagOptions}
                    label="Лейбл"
                    value={form.values.tag}
                    onChange={(value) => form.setFieldValue("tag", value || "")}
                    placeholder="Выберите Лейбл"
                    size="md"
                    radius="md"
                  />
                </Group>
                <Group>
                  <Select
                    style={{ flex: 0.5 }}
                    label="Статус активности"
                    placeholder="Выберите статус"
                    data={["Активен", "Не активен"]}
                    value={getStatusLabel(form.values.active)}
                    onChange={(value) =>
                      form.setFieldValue("active", value === "Активен")
                    }
                    size="md"
                    radius="md"
                  />
                  <TextInput
                    style={{ flex: 0.5, width: "50%" }}
                    label="ID"
                    placeholder="ID"
                    {...form.getInputProps("id")}
                    readOnly
                    styles={inputStyles}
                    size="md"
                    radius="md"
                  />
                </Group>
              </>
            )}
            <Group>
              <h2 className="basefont-h2">Добавленные товары</h2>
              <ProductsTable
                headers={headers}
                productsIds={collectionProduct}
                type="selected"
                handleProduct={handleProduct}
              />
            </Group>

            <Group>
              <h2 className="basefont-h2">Добавить товары</h2>
              <ProductsTable
                headers={headers}
                productsIds={collectionProduct}
                type="select"
                handleProduct={handleProduct}
              />
            </Group>
          </div>
        </>
      )}
    </div>
  );
};

export default CollectionForm;
