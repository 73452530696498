import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import Paginator from "../../shared/paginator";
import { News, NewsQueryBody } from "../../lib/api/types/news";
import { getAllNews } from "../../lib/api/news";
import { format } from "date-fns";

interface PromotionTableProps {
  onRowClick: (article: News) => void;
  openedSideBar: boolean;
}

const PromotionTable: React.FC<PromotionTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [createdFrom, setCreateFrom] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string[] | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [data, setData] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchNews = async (params: NewsQueryBody) => {
    setLoading(true);
    const response = await getAllNews(params);
    setData(response.news);
    setTotal(response.total);
    setLoading(false);
  };

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setCreateFrom([null, null]);
    setSearchId("");
    setSelectedTag(null);
    setSelectedStatus(null);
    setPage(1);
  };

  useEffect(() => {
    const activeStatus =
      selectedStatus?.includes("Все") || selectedStatus === null
        ? [false, true]
        : selectedStatus?.map((v) => v === "Активен");

    const newsType =
      selectedTag?.includes("Все") || selectedTag === null
        ? ["news", "promo"]
        : selectedTag?.map((v) => {
          if (v === "Акция") return "news";
          if (v === "Новость") return "promo";
          return null;
        }) as string[];

    const offset = (activePage - 1) * selectedRowsNumber;

    let created_from = null;
    let created_to = null;
    let date_from = null;
    let date_to = null;

    if (dateValue[0]) {
      const startDate = new Date(dateValue[0]);
      startDate.setHours(0, 0, 0, 0);
      created_from = startDate.toISOString();

      if (dateValue[1]) {
        const endDate = new Date(dateValue[1]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      } else {
        const endDate = new Date(dateValue[0]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      }
    }

    if (createdFrom[0]) {
      const startDate = new Date(createdFrom[0]);
      startDate.setHours(0, 0, 0, 0);
      date_from = startDate.toISOString();

      if (createdFrom[1]) {
        const endDate = new Date(createdFrom[1]);
        endDate.setHours(23, 59, 59, 999);
        date_to = endDate.toISOString();
      } else {
        const endDate = new Date(createdFrom[0]);
        endDate.setHours(23, 59, 59, 999);
        date_to = endDate.toISOString();
      }
    }

    const params: NewsQueryBody = {
      search: searchId,
      active: activeStatus,
      type: newsType,
      created_from: created_from,
      created_to: created_to,
      date_from: date_from,
      date_to: date_to,
      offset: offset,
      limit: selectedRowsNumber,
    };
    fetchNews(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchId,
    createdFrom,
    dateValue,
    selectedStatus,
    selectedTag,
    activePage,
    selectedRows,
  ]);

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Активен", "Не Активен"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const handleTagChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedTag?.includes("Все")) {
        setSelectedTag(["Все", "Акция", "Новость"]);
      } else if (selectedTag?.includes("Все")) {
        const newTag = value.filter((v) => v !== "Все");
        setSelectedTag(newTag);
      }
    } else {
      if (selectedTag?.includes("Все")) {
        setSelectedTag(null);
      } else if (value.length === 0) {
        setSelectedTag(null);
      } else {
        setSelectedTag(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 1, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Сроки акции"
        placeholder="Любые"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={createdFrom}
        onChange={(newValue) => {
          setCreateFrom(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Тег"
        data={["Все", "Акция", "Новость"]}
        value={selectedTag ? selectedTag : []}
        onChange={handleTagChange}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не Активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID компонента",
    "Название",
    "Сроки акции",
    "Тег",
    "Статус активности",
  ];

  const headerDataKeyMap = {
    "ID компонента": "idPromotion",
    "Название": "title",
    "Сроки акции": "publicationDate",
    "Тег": "tag",
    "Статус активности": "status",
  };

  const clickableHeaders = ["Название"];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активен":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Не Активен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (promotion: News) => {
    props.onRowClick(promotion);
  };

  const formattedData = data.map((promotion) => ({
    idPromotion: promotion.id.toString(),
    title: promotion.title,
    publicationDate: `с ${format(
      new Date(promotion.start_date),
      "dd.MM.yyyy"
    )} по ${format(new Date(promotion.end_date), "dd.MM.yyyy")}`,
    tag: promotion.type === "news" ? "Акция" : "Новость",
    status: promotion.active ? "Активен" : "Не Активен",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="promotion"
          loading={loading}
          customData={data}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default PromotionTable;
