import { Pagination, Select } from "@mantine/core";
import './paginator.css'
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { useState } from "react";
interface PagniatorProps {
    total: number;
    selectedRowsNumber: number;
    activePage: number;
    setPage: (page: number) => void
    selectedRows: string
    setSelectedRows: (row: string) => void
}
const Paginator = ({ total, selectedRowsNumber, activePage, setPage, selectedRows, setSelectedRows }: PagniatorProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
            }}
        >
            <div
                style={{ display: "flex", justifyContent: "flex-start" }}
                className="rowsPage"
            >
                <p
                    style={{
                        fontSize: "14px",
                        color: "#1E2134",
                        marginRight: "1rem",
                    }}
                >
                    Строк в таблице
                </p>
                <Select
                    defaultValue="8"
                    data={["8", "16", "32", "64"]}
                    value={selectedRows}
                    size="sm"
                    radius="md"
                    withCheckIcon={false}
                    onChange={(value) => {
                        if (!value) {
                            setSelectedRows("8");
                        } else {
                            setSelectedRows(value as string);
                        }
                        setPage(1);
                    }}
                    onDropdownOpen={() => setIsOpen(true)}
                    onDropdownClose={() => setIsOpen(false)}
                    rightSection={isOpen ? <FiChevronUp /> : <FiChevronDown />}
                    style={{ marginRight: "2rem", width: "70px" }}
                    max={70}
                />
            </div>
            <Pagination
                total={Math.ceil(total / selectedRowsNumber)}
                value={activePage}
                onChange={setPage}
                mt="sm"
            />
        </div>
    )
}

export default Paginator