import React, { useEffect, useState } from 'react';
import { Modal, Select, TextInput, Text, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconTrash } from '@tabler/icons-react';
import { Button } from '../../shared/button';
import { Banner, BannerFormData } from '../../lib/api/types/banners';
import {
  createBanner,
  getBannerById,
  updateBanner,
  uploadImage,
} from '../../lib/api/banner';
import { useToast } from "@chakra-ui/react";

interface UploadedImage {
  file?: File;
  url: string;
}

interface BannerModalProps {
  opened: boolean;
  close: () => void;
  modalTitle: string;
  id: string | null;
  setUpdateData: (data: any) => void;
}

const BannerModal: React.FC<BannerModalProps> = ({
  opened,
  close,
  modalTitle,
  id,
  setUpdateData,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const form = useForm<Banner>({
    initialValues: {
      id: "",
      type: "big",
      position: 0,
      name: "",
      active: false,
      created: "",
      url: "",
      desktop_image_url: "",
      mobile_image_url: "",
    },
    validate: {
      type: (value) => (value ? null : "Тип обязателен"),
      position: (value) => (typeof value === "number" ? null : "Позиция обязательна и должна быть числом"),
      name: (value) => (value ? null : "Имя обязательно"),
      active: (value) => (typeof value === "boolean" ? null : "Статус активности обязателен"),
      url: (value) => (value ? null : "URL обязателен"),
    },
  });

  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const [numberOfBanners, setNumberOfBanners] = useState<string[]>(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'])

  useEffect(() => {
    const fetchBanner = async () => {
      if (id) {
        setLoading(true);
        try {
          const banner = await getBannerById(id);
          form.setValues({
            id: banner.id,
            type: banner.type,
            position: banner.position,
            name: banner.name,
            url: banner.url,
            active: banner.active,
            created: banner.created,
            mobile_image_url: banner.mobile_image_url,
            desktop_image_url: banner.desktop_image_url,
          });
          const existingImages: UploadedImage[] = [];
          if (banner.desktop_image_url) {
            existingImages.push({ url: banner.desktop_image_url });
          }
          if (banner.mobile_image_url) {
            existingImages.push({ url: banner.mobile_image_url });
          }
          setUploadedImages(existingImages);
        } catch (error) {
          console.error("Failed to fetch banner:", error);
          toast({
            title: "Ошибка",
            description: "Ошибка сервера.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      } else {
        form.reset();
        setUploadedImages([]);
        setLoading(false);
      }
    };
    fetchBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form.values.type !== "big") {
      setUploadedImages((prev) => prev.slice(0, 1));
      setNumberOfBanners(['1', '2', '3'])
    }
    else if (form.values.type === "big") {
      setNumberOfBanners(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'])
    }
  }, [form.values.type]);

  const typeLabels: { [key: string]: string } = {
    big: "Большой",
    medium: "Средний",
    small: "Маленький",
  };

  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не активен";

  const handleBanner = async (
    values: Banner,
    uploadedImages: UploadedImage[]
  ) => {
    setIsSubmitting(true);
    const bannerData: BannerFormData = {
      active: values.active,
      position: values.position,
      name: values.name,
      type: values.type,
      url: values.url,
    };
    try {
      let bannerId = values.id;
      if (values.id) {
        await updateBanner(values.id, bannerData);
      } else {
        const createdBanner = await createBanner(bannerData);
        bannerId = createdBanner.id;
      }

      if (uploadedImages.length === 0 || !uploadedImages.some(img => img.file)) {
        if (!values.id) {
          if (values.type === "big") {
            await uploadImage(`/banners/${bannerId}/desktop-image/image.jpg`, null);
            await uploadImage(`/banners/${bannerId}/mobile-image/image.jpg`, null);
          } else {
            await uploadImage(`/banners/${bannerId}/desktop-image/image.jpg`, null);
          }
        }
      } else {
        const file = uploadedImages[0].file;
        await uploadImage(
          `/banners/${bannerId}/desktop-image/image.jpg`,
          file || null
        );
        if (values.type === "big" && uploadedImages.length === 2) {
          const file = uploadedImages[1].file;
          await uploadImage(`/banners/${bannerId}/mobile-image/image.jpg`, file || null);
        }
      }

      setUpdateData((prev: any) => !prev);

      toast({
        title: "Успех",
        description: modalTitle.includes("Создание") ? "Баннер успешно добавлен." : "Баннер успешно обновлен.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setTimeout(() => {
        form.reset();
        close();
      }, 1300);
    } catch (error) {
      console.error("Failed to add/update banner:", error);
      toast({
        title: "Ошибка",
        description: "Ошибка сервера.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    await handleBanner(form.values, uploadedImages);
  };

  const handleDrop = (files: File[]) => {
    const newImages = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setUploadedImages((prev) => [...prev, ...newImages]);
  };

  const handleDeleteImage = (index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index));
  };

  const imageExplaination = form.values.type === "big" ?
    "Добавьте баннер для компьютера (1232:489)" :
    form.values.type === "medium" ?
      "Добавьте баннер (400:512)" :
      "Добавьте баннер (1232:280)";

  useEffect(() => {
    if (!opened) {
      setIsSubmitting(false);
      form.reset();
      setUploadedImages([]);
    }
  }, [opened, form]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={modalTitle}
      centered
      className="personal-model"
    >
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Select
            {...form.getInputProps("type")}
            label="Тип баннера"
            placeholder="Выберите тип баннера"
            data={["Большой", "Средний", "Маленький"]}
            size="md"
            radius="md"
            style={{ padding: "12px" }}
            value={typeLabels[form.values.type]}
            onChange={(value) => {
              if (!value) {
                form.setFieldValue("type", "big");
              }
              else {
                const selectedType = Object.keys(typeLabels).find((key) => typeLabels[key] === value);
                form.setFieldValue("type", selectedType!);
                if (selectedType !== "big") {
                  setUploadedImages((prev) => prev.slice(0, 1));
                }
              }
            }}
          />
          {form.values.type !== "small" && (
            <Select
              {...form.getInputProps("position")}
              label="Очередность на сайте"
              placeholder="Выберите очередность баннера"
              data={numberOfBanners}
              size="md"
              radius="md"
              style={{ padding: "12px" }}
              value={form.values.position.toString()}
              onChange={(value) =>
                form.setFieldValue("position", parseInt(value as string))
              }
            />
          )}
          <TextInput
            label="Название"
            {...form.getInputProps("name")}
            placeholder="Введите название"
            style={{ padding: "12px" }}
            value={form.values.name}
            onChange={(event) =>
              form.setFieldValue("name", event.currentTarget.value)
            }
          />
          <div style={{ padding: "12px" }}>
            <TextInput
              label="Куда ведет баннер"
              {...form.getInputProps("url")}
              placeholder="Вставьте ссылку"
              value={form.values.url}
              onChange={(event) =>
                form.setFieldValue("url", event.currentTarget.value)
              }
            />
            <span
              className="text-txt-secondary basefont-t6"
              style={{ display: "block", marginTop: "4px" }}
            >
              Вставьте ссылку, на которую будет вести этот баннер
            </span>
          </div>
          {uploadedImages.length < (form.values.type === "big" ? 2 : 1) && (
            <>
              {form.values.type === "big" && uploadedImages.length < 1 &&
                <>
                  <span className="basefont-t6 ml-3">Добавьте баннер для компьютера (1232:489)</span>
                  <Dropzone
                    onDrop={handleDrop}
                    accept={IMAGE_MIME_TYPE}
                    className="dropzone"
                    maxFiles={1}
                  >
                    <Text ta="center">
                      Загрузить изображение <br /> png, jpg
                    </Text>
                  </Dropzone>
                </>
              }
              {form.values.type === "big" && <span className="basefont-t6 ml-3">
                Добавьте баннер для мобильной версии (1:1)
              </span>}
              {form.values.type === "medium" && <span className="basefont-t6 ml-3">
                Добавьте баннер (400:512)
              </span>}
              {form.values.type === "small" && <span className="basefont-t6 ml-3">
                Добавьте баннер (1232:280)
              </span>}
              <Dropzone
                onDrop={handleDrop}
                accept={IMAGE_MIME_TYPE}
                className="dropzone"
                maxFiles={1}
              >
                <Text ta="center">
                  Загрузить изображение <br /> png, jpg
                </Text>
              </Dropzone>
            </>
          )}
          <div className="mt-[20px]">
            {uploadedImages.map((image, index) => (
              <div
                key={index}
                className="flex flex-col items-left ml-[1rem] mb-[1rem]"
                style={{ display: form.values.type === "big" || index === 0 ? "flex" : "none" }}
              >
                {index === 0 && (
                  <span className="basefont-t5">{imageExplaination}</span>
                )}
                {index === 1 && form.values.type === "big" && (
                  <span className="basefont-t5">
                    Добавьте баннер для мобильной версии (1:1)
                  </span>
                )}
                <div className="flex items-center w-full">
                  <img
                    src={image.url}
                    alt={`Uploaded ${index}`}
                    className="w-[100px] h-[100px] object-cover mr-[10px] rounded-xl"
                  />
                  <span className="mr-[16px]">photo.jpg</span>
                  <IconTrash
                    size={16}
                    color="#0560BE"
                    onClick={() => handleDeleteImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>

          <Select
            label="Статус активности"
            {...form.getInputProps("active")}
            placeholder="Выберите статус активности"
            data={["Активен", "Не активен"]}
            size="md"
            radius="md"
            style={{ padding: "12px" }}
            value={getStatusLabel(form.values.active)}
            onChange={(value) =>
              form.setFieldValue("active", value === "Активен")
            }
          />
          <div className="flex flex-col gap-2 mt-2">
            <Button
              type="submit"
              style={{
                backgroundColor: "#0560BE",
                width: "410px",
                height: "48px",
                padding: "8px, 20px, 8px, 20px",
                marginLeft: "10px",
              }} disabled={isSubmitting} 
              >
                {isSubmitting ? (
                  <>
                    <Loader size="sm" color="white" /> {/* Индикатор загрузки */}
                    <span style={{ marginLeft: 8 }}>Сохраняется...</span>
                  </>
                ) : (
                  modalTitle.includes("Создание") ? "Добавить" : "Сохранить"
                )}
            </Button>
            {modalTitle === "Редактировать баннер" && (
              <Button
                variant="outline"
                onClick={close}
                style={{
                  width: "410px",
                  height: "48px",
                  padding: "8px, 20px, 8px, 20px",
                  marginLeft: "10px",
                }} disabled={isSubmitting}
              >
                Отменить
              </Button>
            )}
          </div>
        </form>
      )}
    </Modal>
  );
};

export default BannerModal;
