import axios from "./base";
import {
  Article,
  GetArticlesParams,
  GetArticlesResponse,
  GetArticleByIdResponse,
  ArticleUpdateData,
  CreateArticleData,
} from "./types/articles";

export const getArticles = async (
  params: GetArticlesParams
): Promise<GetArticlesResponse> => {
  const response = await axios.post("/articles/query", params);
  return response.data;
};

export const getArticleById = async (id: string): Promise<Article> => {
  const response = await axios.get<GetArticleByIdResponse>(`/articles/${id}`);
  return response.data.article;
};

export const createArticle = async (
  article: CreateArticleData
): Promise<{ id: string }> => {
  const response = await axios.post("/articles", article);
  return response.data;
};

export const uploadArticleBodyImage = async (url: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    await axios.request({
      method: "POST",
      url: `https://new.999111.ru/admin/api/v1${url}`,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (error) {
    console.error("Failed to upload image:", error);
    throw error;
  }
};


export const updateArticle = async (
  id: string,
  article: ArticleUpdateData
): Promise<{ id: string }> => {
  const response = await axios.put(`/articles/${id}`, article);
  return response.data;
};

export const getCategories = async ({
  debouncedSearchTerm,
  offset,
  limit,
}: {
  debouncedSearchTerm: string | null;
  offset: number;
  limit: number;
}) => {
  return await axios.post("/catalog/categories/query", {
    search: debouncedSearchTerm,
    offset: offset,
    limit: limit,
  });
};
