import React, { useEffect, useState } from "react";
import { Button, MantineProvider, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { CalendarEvent, Search } from "tabler-icons-react";
import TablePattern from "../table-pattern";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { fetchOrders } from "../../lib/api/orders";
import { OrderResponse, Order } from "../../lib/api/types/orders";
import { format } from "date-fns";
import { formatRuble } from "../../lib/utils";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "../table.css";
import Paginator from "../../shared/paginator";

interface OrdersTableProps {
  editOrder: (value: boolean, order: Order | null) => void;
  openedSideBar: boolean;
}

const statusTranslations: { [key: string]: string } = {
  confirmation_waiting: "Ожидает подтверждения",
  payment_waiting: "Ожидает оплаты",
  paid: "Оплачен",
  preparing: "Сборка",
  sent_for_delivery: "Передан в доставку",
  ready_for_receiving: "Готов к получению",
  received: "Получен",
  cancelled: "Отменен",
};

const statusTranslationsReverse: { [key: string]: string } = {
  "Ожидает подтверждения": "confirmation_waiting",
  "Ожидает оплаты": "payment_waiting",
  Оплачен: "paid",
  Сборка: "preparing",
  "Передан в доставку": "sent_for_delivery",
  "Готов к получению": "ready_for_receiving",
  Получен: "received",
  Отменен: "cancelled",
};

const paymentMethodTranslations: { [key: string]: string } = {
  Картой: "card",
  Наличными: "cash",
  Долями: "invoice",
};

const receiveTypeTranslations: { [key: string]: string } = {
  Доставка: "delivery",
  Самовывоз: "store",
  "Пункт выдачи": "pickup_point",
};

const paymentMethodTranslationsReverse: { [key: string]: string } = {
  card: "Картой",
  cash: "Наличными",
  invoice: "Долями",
};

const receiveTypeTranslationsReverse: { [key: string]: string } = {
  delivery: "Доставка",
  store: "Самовывоз",
  pickup_point: "Пункт выдачи",
};

const statusStyles: { [key: string]: React.CSSProperties } = {
  "Ожидает подтверждения": {
    backgroundColor: "#FFECDA",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  "Ожидает оплаты": {
    backgroundColor: "#DEF7F0",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  Оплачен: {
    backgroundColor: "#E3EDFD",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  Сборка: {
    backgroundColor: "#E3EDFD",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  "Передан в доставку": {
    backgroundColor: "#FBEFFF",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  "Готов к получению": {
    backgroundColor: "#FFECDA",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  Получен: {
    backgroundColor: "#DEF7F0",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
  Отменен: {
    backgroundColor: "#FDE3E3",
    borderRadius: 16,
    padding: "8px 16px",
    fontWeight: 500,
  },
};

const OrdersTable: React.FC<OrdersTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedPayment, setSelectedPayment] = useState<string[] | null>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<string[] | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [data, setData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const offset = (activePage - 1) * selectedRowsNumber;
      const translatedPayment =
        selectedPayment?.map(
          (method) => paymentMethodTranslations[method] || method
        ) || null;

      const translatedReceiveTypes =
        selectedDelivery?.map(
          (type) => receiveTypeTranslations[type] || type
        ) || null;

      const translatedStatus =
        selectedStatus?.map(
          (status) => statusTranslationsReverse[status] || status
        ) || null;

      let date_from = null;
      let date_to = null;

      if (dateValue[0]) {
        const startDate = new Date(dateValue[0]);
        startDate.setHours(0, 0, 0, 0);
        date_from = startDate.toISOString();

        if (dateValue[1]) {
          const endDate = new Date(dateValue[1]);
          endDate.setHours(23, 59, 59, 999);
          date_to = endDate.toISOString();
        } else {
          const endDate = new Date(dateValue[0]);
          endDate.setHours(23, 59, 59, 999);
          date_to = endDate.toISOString();
        }
      }

      const params = {
        search: searchId,
        statuses: translatedStatus,
        payment_methods: translatedPayment,
        receive_types: translatedReceiveTypes,
        date_from: date_from,
        date_to: date_to,
        offset: offset,
        limit: selectedRowsNumber,
      };

      const response: OrderResponse = await fetchOrders(params);
      setData(response.orders);
      setTotal(response.total || 20);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchId,
    dateValue,
    activePage,
    selectedRows,
    selectedDelivery,
    selectedPayment,
    selectedStatus,
  ]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedPayment(null);
    setSelectedDelivery(null);
    setSelectedStatus(null);
    setPage(1);
  };

  const handleMultiChooseSelect = (
    value: string[],
    choices: string[],
    setValue: (data: any) => void,
    data: string[] | null
  ) => {
    if (value.includes("Все")) {
      if (!data?.includes("Все")) {
        setValue(choices);
      } else if (data?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setValue(newStatus);
      }
    } else {
      if (data?.includes("Все")) {
        setValue(null);
      } else if (value.length === 0) {
        setValue(null);
      } else {
        setValue(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 1, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Оплата"
        data={["Все", "Наличными", "Картой", "Долями"]}
        value={selectedPayment ? selectedPayment : []}
        onChange={(value: any) => {
          handleMultiChooseSelect(
            value,
            ["Все", "Наличными", "Картой", "Долями"],
            setSelectedPayment,
            selectedPayment
          );
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Получение"
        data={["Все", "Доставка", "Самовывоз", "Пункт выдачи"]}
        value={selectedDelivery ? selectedDelivery : []}
        onChange={(value: any) => {
          handleMultiChooseSelect(
            value,
            ["Все", "Доставка", "Самовывоз", "Пункт выдачи"],
            setSelectedDelivery,
            selectedDelivery
          );
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус"
        data={[
          "Все",
          "Ожидает подтверждения",
          "Ожидает оплаты",
          "Оплачен",
          "Сборка",
          "Передан в доставку",
          "Готов к получению",
          "Получен",
          "Отменен",
        ]}
        value={selectedStatus ? selectedStatus : []}
        onChange={(value: any) => {
          handleMultiChooseSelect(
            value,
            [
              "Все",
              "Ожидает подтверждения",
              "Ожидает оплаты",
              "Оплачен",
              "Сборка",
              "Передан в доставку",
              "Готов к получению",
              "Получен",
              "Отменен",
            ],
            setSelectedStatus,
            selectedStatus
          );
        }}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          marginTop: "26px",
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "Заказ",
    "Дата",
    "Сумма",
    "Клиент",
    "Номер телефона",
    "Оплата",
    "Получение",
    "Статус",
  ];

  const headerDataKeyMap = {
    Заказ: "id",
    Дата: "created",
    Сумма: "total",
    Клиент: "name",
    "Номер телефона": "Номер телефона",
    Оплата: "Оплата",
    Получение: "Получение",
    Статус: "status",
  };

  const clickableHeaders = ["Заказ"];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    return statusStyles[status] || {};
  };

  const handleRowClick = (order: Order) => {
    props.editOrder(true, order);
  };

  const formattedData = data.map((order) => {
    const translatedStatus = statusTranslations[order.status] || order.status;
    const translatedPaymentMethod =
      paymentMethodTranslationsReverse[order.payment_method] ||
      order.payment_method;
    const translatedReceiveType =
      receiveTypeTranslationsReverse[order.receive_type] || order.receive_type;

    return {
      id: order.number.toString(),
      created: format(new Date(order.date), "dd.MM.yyyy"),
      total: formatRuble(order.price_total),
      name:
        order.user.first_name +
        " " +
        order.user.last_name +
        " " +
        order.user.father_name,
      "Номер телефона": order.user.phone,
      Оплата: translatedPaymentMethod,
      Получение: translatedReceiveType,
      status: translatedStatus,
    };
  });

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          customDataType="orders"
          customData={data}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />

        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default OrdersTable;
