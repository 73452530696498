import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import { getReviews } from "../../lib/api/review";
import { GetReviewsParams, Review } from "../../lib/api/types/review";
import Paginator from "../../shared/paginator";
import { format } from "date-fns";

interface ReviewTableProps {
  editReview: (
    value: boolean,
    review: { item_id: string; user_id: string }
  ) => void;
  openedSideBar: boolean;
}

const ReviewTable: React.FC<ReviewTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [data, setData] = useState<Review[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchReviews = async (params: GetReviewsParams) => {
    setLoading(true);
    const response = await getReviews(params);
    setData(response.reviews);
    setTotal(response.total);
    setLoading(false);
  };

  useEffect(() => {
    const statuses =
      selectedStatus?.includes("Все") || selectedStatus === null
        ? ["approved", "pending", "rejected"]
        : selectedStatus?.map((status) => {
          switch (status) {
            case "Подтвержден":
              return "approved";
            case "Отклонен":
              return "rejected";
            case "Ожидает подтверждения":
              return "pending";
            default:
              return status;
          }
        });
    const offset = (activePage - 1) * selectedRowsNumber;

    let created_from = null;
    let created_to = null;

    if (dateValue[0]) {
      const startDate = new Date(dateValue[0]);
      startDate.setHours(0, 0, 0, 0);
      created_from = startDate.toISOString();

      if (dateValue[1]) {
        const endDate = new Date(dateValue[1]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      } else {
        const endDate = new Date(dateValue[0]);
        endDate.setHours(23, 59, 59, 999);
        created_to = endDate.toISOString();
      }
    }

    const params: GetReviewsParams = {
      search: searchId,
      created_from: created_from,
      created_to: created_to,
      statuses: statuses,
      offset: offset,
      limit: selectedRowsNumber,
    };
    fetchReviews(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, dateValue, selectedStatus, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    setPage(1);
  };

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus([
          "Все",
          "Подтвержден",
          "Отклонен",
          "Ожидает подтверждения",
        ]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ width: "704px", marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: props.openedSideBar ? `calc(100% - 5px)` : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус модерации"
        data={["Все", "Подтвержден", "Отклонен", "Ожидает подтверждения"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = ["ID отзыва", "Дата создания", "Статус модерации"];

  const headerDataKeyMap = {
    "ID отзыва": "idReview",
    "Дата создания": "creationDate",
    "Статус модерации": "moderationStatus",
  };

  const clickableHeaders = ["Дата создания"];

  const getFieldToStyle = () => "moderationStatus";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Подтвержден":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Отклонен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Ожидает подтверждения":
        return {
          backgroundColor: "#FFECDA",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (review: Review) => {
    props.editReview(true, {
      item_id: review.item_id,
      user_id: review.user_id,
    });
  };

  const formattedData = data.map((review) => ({
    idReview: review.item_id.toString(),
    creationDate: format(new Date(review.created), "dd.MM.yyyy"),
    moderationStatus:
      review.status === "approved"
        ? "Подтвержден"
        : review.status === "rejected"
          ? "Отклонен"
          : "Ожидает подтверждения",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customData={data}
          customDataType="review"
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default ReviewTable;
