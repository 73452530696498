import { twMerge } from "tailwind-merge";

export type IconProps = {
  name: string;
  interactive?: boolean;
  inline?: boolean;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, "width" | "height">;

export const Icon = ({
  inline,
  interactive = false,
  className,
  ...props
}: IconProps) => {
  return (
    <img
      src={`/icons/${props.name}.svg`}
      alt=""
      className={twMerge(
        `${interactive ? "" : "pointer-events-none"} aspect-square`,
        inline && "inline w-[1lh] h-[1lh]",
        className
      )}
      width={32}
      height={32}
      {...props}
    />
  );
};
