import axios from "./base";
import { EDO, GetEDOsParams } from "./types/edo";

export const getEDOs = async (params: GetEDOsParams): Promise<{ edm: EDO[]; total: number }> => {
  const response = await axios.post("/edm/query", params);
  return response.data;
};

export const getEDOById = async (id: string): Promise<EDO> => {
  const response = await axios.get(`/edm/${id}`);
  return response.data.edm;
};

export const updateEDO = async (id: string, status: string) => {
  const response = await axios.put(`/edm/${id}`, { status:status });
  return response.data;
};
