import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}


export function formatRuble(amountInKopecks: number): string {
  const rubles = amountInKopecks / 100;

  let rublesStr = rubles.toFixed(2);
  rublesStr = rublesStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return `${rublesStr} ₽`;
}


export const formatPhone = (str: string) => {
  return str.replace(/[^0-9]/g, "");
};
