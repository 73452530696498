import axios from "./base";
import { GetStoresParams, Store } from "./types/store";

export const getStores = async (
  params: GetStoresParams
): Promise<{ stores: Store[]; total: number }> => {
  const response = await axios.post("/stores/query", params);
  return response.data;
};

export const updateStore = async (store: Store): Promise<void> => {
  await axios.post("/stores/update", store);
};

export const addStore = async (store: Store): Promise<void> => {
  await axios.post("/stores/add", store);
};

export const getCities = async (
  params: GetStoresParams
): Promise<{ cities: string[] }> => {
  const response = await axios.get("/stores/cities", { params: params });
  return response.data;
};
