import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import { getArticles } from "../../lib/api/articles";
import { GetArticlesParams, Article } from "../../lib/api/types/articles";
import Paginator from "../../shared/paginator";
import { format } from "date-fns";

interface ArticlesTableProps {
  onRowClick: (article: Article) => void;
  openedSideBar: boolean;
}

const ArticlesTable: React.FC<ArticlesTableProps> = ({
  onRowClick,
  openedSideBar,
}) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [data, setData] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchArticles = async (params: GetArticlesParams) => {
    const response = await getArticles(params);
    setData(response.articles);
    setTotal(response.total);
  };

  useEffect(() => {
    const fetchArticlesData = async () => {
      setLoading(true);
      const activeStatus =
        selectedStatus?.includes("Все") || selectedStatus === null
          ? [false, true]
          : selectedStatus?.map((v) => v === "Активен");
      const offset = (activePage - 1) * selectedRowsNumber;

      let created_from = null;
      let created_to = null;

      if (dateValue[0]) {
        const startDate = new Date(dateValue[0]);
        startDate.setHours(0, 0, 0, 0);
        created_from = startDate.toISOString();

        if (dateValue[1]) {
          const endDate = new Date(dateValue[1]);
          endDate.setHours(23, 59, 59, 999);
          created_to = endDate.toISOString();
        } else {
          const endDate = new Date(dateValue[0]);
          endDate.setHours(23, 59, 59, 999);
          created_to = endDate.toISOString();
        }
      }

      const params: GetArticlesParams = {
        search: searchId,
        active: activeStatus,
        created_from,
        created_to,
        offset: offset,
        limit: selectedRowsNumber,
      };
      await fetchArticles(params);
      setLoading(false);
    };

    fetchArticlesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, dateValue, selectedStatus, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    setPage(1);
  };

  const handleStatusChange = (value: string[]) => {
    if (value.includes("Все")) {
      if (!selectedStatus?.includes("Все")) {
        setSelectedStatus(["Все", "Активен", "Не Активен"]);
      } else if (selectedStatus?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setSelectedStatus(newStatus);
      }
    } else {
      if (selectedStatus?.includes("Все")) {
        setSelectedStatus(null);
      } else if (value.length === 0) {
        setSelectedStatus(null);
      } else {
        setSelectedStatus(value);
      }
    }
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ width: "704px", marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: openedSideBar ? `calc(100% - 5px)` : '',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        allowSingleDateInRange
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не Активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={handleStatusChange}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID статьи",
    "Название статьи",
    "Дата создания",
    "Категория",
    "Статус активности",
  ];

  const headerDataKeyMap = {
    "ID статьи": "id",
    "Название статьи": "title",
    "Дата создания": "creationDate",
    "Категория": "category",
    "Статус активности": "status",
  };

  const clickableHeaders = ["Название статьи"];

  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активна":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Не активна":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (article: Article) => {
    onRowClick(article);
  };

  const formattedData = data.map((article) => ({
    id: article.id.toString(),
    title: article.title,
    creationDate: format(new Date(article.created), "dd.MM.yyyy"),
    category: article.category.name,
    status: article.active ? "Активна" : "Не активна",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedData}
          dataKeyAccessor={headerDataKeyMap}
          clickableHeaders={clickableHeaders}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customData={data}
          customDataType="article"
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default ArticlesTable;
